import styled from "@emotion/styled";
import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectPreviewProduct } from "../campaignCreateSlice";
import clsx from "clsx";

const classTopIsland = clsx(
  `relative z-20`,
  `bg-grey-dark-scale-600/40`,
  `mx-auto mt-6 2xl:mt-10`,
  `rounded-md py-2.5 px-3`,
  `flex content-between`,
);

const classBottomIsland = clsx(
  "relative z-20",
  "flex gap-x-2 p-2.5",
  "bg-white/10",
  "mx-auto mt-8",
  "rounded-[14px] p-2.5 mb-6",
);

const classThumbnail = clsx(
  "bottom-island-thumbnail",
  "flex items-center justify-center",
  "bg-white/80 overflow-hidden cursor-pointer",
  "w-[25%] rounded-[8px] aspect-square",
);

const RootDiv = styled.div`
  .top-island-controls > * {
    transform: scale(0.8);
  }
  .bottom-island-thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    img {
      object-fit: contain;
      border-radius: 5px;
    }
  }
`;

interface Props {
  setSoundOn: React.Dispatch<React.SetStateAction<boolean>>;
  soundOn: boolean;
}
const InitialScreenContent: React.FC<Props> = ({ setSoundOn, soundOn }) => {
  const dispatch = useAppDispatch();

  const topIslandBackgroundColor = useAppSelector(
    state => state.campaignCreate.topIslandBackgroundColor,
  );
  const topIslandTextColor = useAppSelector(state => state.campaignCreate.topIslandTextColor);
  const islandBackgroundColor = useAppSelector(
    state => state.campaignCreate.bottomIslandBackgroundColor,
  );
  const islandThumbnailsBackgroundColor = useAppSelector(
    state => state.campaignCreate.bottomIslandThumbnailsBackgroundColor,
  );
  const thumbnailsPadding = useAppSelector(
    state => state.campaignCreate.bottomIslandThumbnailsPadding,
  );
  const headline = useAppSelector(state => state.campaignCreate.headline);

  const productImages = useAppSelector(state => state.campaignCreate.productImages);
  const video = useAppSelector(state => state.campaignCreate.video);
  const brandName = useAppSelector(state => state.campaignCreate.brandName);

  const getProductImage = (imagesData: any, index: number) => {
    if (imagesData && imagesData[index] && imagesData[index][0] && imagesData[index][0].url) {
      return imagesData[index][0].url;
    }
    return null;
  };
  const headlineValue = headline ? headline : "Headline";
  const brandNameValue = brandName ? brandName : "Brand Name";
  const productImage1 = getProductImage(productImages, 0);
  const productImage2 = getProductImage(productImages, 1);
  const productImage3 = getProductImage(productImages, 2);
  const productImage4 = getProductImage(productImages, 3);

  const getSoundContent = () => {
    if (!video) return null;
    if (soundOn) {
      return <SoundOnIcon onClick={() => setSoundOn(false)} />;
    }
    return <SoundOffIcon onClick={() => setSoundOn(true)} />;
  };
  const thumbnailStyle = {
    backgroundColor: islandThumbnailsBackgroundColor,
    padding: `${thumbnailsPadding}px`,
  };
  return (
    <RootDiv className="w-full h-full flex flex-col justify-between z-20 bg-grey-light-scale-600 dark:bg-grey-dark-scale-200">
      <div
        className={classTopIsland}
        style={{ backgroundColor: topIslandBackgroundColor, width: "calc(100% - 40px)" }}
      >
        <div className="flex flex-col gap-0.5">
          <PSmall isMedium className="leading-none pb-2" style={{ color: topIslandTextColor }}>
            {brandNameValue}
          </PSmall>
          <PSmall className="leading-none" style={{ color: topIslandTextColor }}>
            {headlineValue}
          </PSmall>
        </div>
        <div className="top-island-controls flex gap-1 ml-auto">
          {getSoundContent()}
          <CloseIcon style={{ color: topIslandTextColor }} />
        </div>
      </div>
      <div
        className={classBottomIsland}
        style={{ backgroundColor: islandBackgroundColor, width: "calc(100% - 40px)" }}
      >
        <div
          className={classThumbnail}
          onClick={() => dispatch(selectPreviewProduct(1))}
          style={thumbnailStyle}
        >
          {productImage1 ? <img src={productImage1} alt="thumb_1" /> : null}
        </div>
        <div
          className={classThumbnail}
          onClick={() => dispatch(selectPreviewProduct(2))}
          style={thumbnailStyle}
        >
          {productImage2 ? <img src={productImage2} alt="thumb_2" /> : null}
        </div>
        <div
          className={classThumbnail}
          onClick={() => dispatch(selectPreviewProduct(3))}
          style={thumbnailStyle}
        >
          {productImage3 ? <img src={productImage3} alt="thumb_3" /> : null}
        </div>
        <div
          className={classThumbnail}
          onClick={() => dispatch(selectPreviewProduct(4))}
          style={thumbnailStyle}
        >
          {productImage4 ? <img src={productImage4} alt="thumb_4" /> : null}
        </div>
      </div>
    </RootDiv>
  );
};
export default InitialScreenContent;

function CloseIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="currentColor"
        fillOpacity="0.48"
        fillRule="evenodd"
        d="M9.5 19A9.5 9.5 0 0019 9.5 9.5 9.5 0 009.5 0 9.5 9.5 0 000 9.5 9.5 9.5 0 009.5 19zm4.7-13.03l-3.547 3.512 4.27 4.27c.103.103.103.275 0 .413l-.758.758a.359.359 0 01-.264.073.259.259 0 01-.149-.073l-.723-.724-3.512-3.546-4.269 4.27c-.103.102-.275.102-.413 0l-.758-.758c-.103-.138-.103-.31 0-.413l4.27-4.27-4.27-4.234a.277.277 0 01-.074-.238.378.378 0 01.074-.175l.758-.758c.138-.102.31-.102.413 0l4.27 4.27 4.234-4.27c.103-.102.275-.102.413 0l.758.758c.103.138.103.31 0 .413l-.723.723z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function SoundOffIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path fill="currentColor" d="M0 0H19V19H0z" opacity="0.01"></path>
      <path
        fill="currentColor"
        d="M4.56 7.291v4.777l4.517 4.474c.26.26.694 0 .694-.435V3.253c0-.435-.434-.695-.694-.435L4.56 7.291zm0 4.777H1.954a.869.869 0 01-.868-.868V8.16a.869.869 0 01.868-.869H4.56m10.77 8.816a.869.869 0 01-.608-1.476 6.948 6.948 0 000-9.902.868.868 0 011.13-1.129 8.686 8.686 0 010 12.334.868.868 0 01-.609.26l.087-.087z"
      ></path>
      <path
        fill="currentColor"
        d="M12.811 13.588a.869.869 0 01-.608-1.476 3.474 3.474 0 000-4.864.868.868 0 011.216-1.216 5.211 5.211 0 010 7.296.869.869 0 01-.608.26z"
      ></path>
    </svg>
  );
}

function SoundOnIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path fill="#fff" d="M0 0H19V19H0z" opacity="0.01"></path>
      <path
        fill="#fff"
        d="M18.457 17.241l-1.998-1.998-1.216-1.216-1.216-1.216-1.302-1.302L9.77 8.555 6.297 5.081 2.302 1.086 1.086 2.302l4.343 4.212-.869.869H1.954c-.52 0-.868.347-.868.868v3.04c0 .522.347.869.868.869H4.56l4.517 4.473c.26.26.694 0 .694-.434v-5.212l4.864 4.864 2.52 2.52 1.302-1.13zM9.771 3.344c0-.434-.434-.695-.694-.434l-1.39 1.042L9.77 6.123V3.344zm4.951 1.477c2.085 2.084 2.52 5.124 1.477 7.643l1.303 1.303c1.737-3.3 1.216-7.383-1.564-10.162a.913.913 0 00-1.129 0c-.347.347-.434.868-.087 1.216zm-2.519 2.518c.608.608.956 1.39.956 2.259l1.563 1.563a5.433 5.433 0 00-1.303-5.038.913.913 0 00-1.129 0c-.347.261-.347.869-.087 1.216z"
      ></path>
    </svg>
  );
}
