import { H5 } from "@tempoplatform/tpds/elements/typography";
import { Window, Separator } from "@tempoplatform/tpds/elements/layout";
import Select from "@tempoplatform/tpds/components/select";
import Spinner from "@tempoplatform/tpds/components/spinner";
import tokens from "@tempoplatform/tpds/tokens";
import { PTiny } from "@tempoplatform/tpds/elements/typography";
import RadioTabs from "@tempoplatform/tpds/components/radiotabs";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { toGetPublisherCharts } from "../api/api";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  TimeScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Legend,
  ChartOptions,
  Tooltip,
  BarController,
  BarElement,
} from "chart.js";
import "chartjs-adapter-date-fns";

const grey_dark = tokens.colors.color_palettes.grey_dark;
const grey_light = tokens.colors.color_palettes.grey_light;
const light = tokens.colors.light;
const dark = tokens.colors.dark;

interface DataObject {
  date: string;
  app_name: string;
  app_id: string;
  bundle_id: string;
  platform: string;
  country: string;
  ad_unit: string | null;
  ad_type: string;
  revenue: number;
  requests: number;
  clicks: number;
  impressions: number;
}

type FilterOptions = {
  app_name: string;
  app_id: string;
  bundle_id: string;
  platform: string;
  country: string;
  ad_unit: string;
  ad_type: string;
};

const periodTypes = ["Daily", "Monthly"];

Chart.register(
  TimeScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Legend,
  Tooltip,
  BarController,
  BarElement,
);

const extractUniqueValues = <T extends string | number | null>(
  data: DataObject[],
  key: keyof DataObject,
): T[] => {
  const uniqueValues = new Set<T>();
  data.forEach(obj => uniqueValues.add(obj[key] as T));
  return Array.from(uniqueValues);
};

const processData = (
  data: DataObject[],
  filters: FilterOptions,
  isMonthly: boolean,
): {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    yAxisID: string;
    type: string;
    borderWidth: number;
  }[];
} => {
  const filteredData = data.filter(obj =>
    Object.entries(filters).every(
      ([key, value]) =>
        value === "All" ||
        (obj[key as keyof DataObject] != null && obj[key as keyof DataObject] === value),
    ),
  ) as DataObject[];

  const aggregatedData: DataObject[] = filteredData.reduce((acc: { [key: string]: any }, obj) => {
    const date = isMonthly ? obj.date.slice(0, 7) : obj.date.split("T")[0];
    if (!acc[date]) {
      acc[date] = {
        date: date,
        requests: 0,
        impressions: 0,
        clicks: 0,
        revenue: 0,
      };
    }
    acc[date].requests += obj.requests;
    acc[date].impressions += obj.impressions;
    acc[date].clicks += obj.clicks;
    acc[date].revenue += obj.revenue;
    return acc;
  }, {}) as DataObject[];

  const chartLabels: string[] = [];
  const chartRequests: number[] = [];
  const chartImpressions: number[] = [];
  const chartClicks: number[] = [];
  const chartRevenue: number[] = [];

  for (const obj of Object.values(aggregatedData)) {
    chartLabels.push(obj.date);
    chartRequests.push(obj.requests);
    chartImpressions.push(obj.impressions);
    chartClicks.push(obj.clicks);
    chartRevenue.push(obj.revenue);
  }

  return {
    labels: chartLabels,
    datasets: [
      {
        label: "Requests",
        data: chartRequests,
        borderColor: "rgba(255, 99, 132, 1)",
        yAxisID: "count",
        type: isMonthly ? "bar" : "line",
        borderWidth: 2,
      },
      {
        label: "Impressions",
        data: chartImpressions,
        borderColor: "hsl(204 82% 56%)",
        yAxisID: "count",
        type: isMonthly ? "bar" : "line",
        borderWidth: 2,
      },
      {
        label: "Clicks",
        data: chartClicks,
        borderColor: "rgba(255, 206, 86, 1)",
        yAxisID: "count",
        type: isMonthly ? "bar" : "line",
        borderWidth: 2,
      },
      {
        label: "Revenue",
        data: chartRevenue,
        borderColor: "rgba(75,192,192, 1)",
        yAxisID: "revenue",
        type: isMonthly ? "bar" : "line",
        borderWidth: 2,
      },
    ],
  };
};

interface PublisherAppsAnalyticsProps {
  accountId: number;
}

const PublisherAppsAnalytics: React.FC<PublisherAppsAnalyticsProps> = ({ accountId }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [isMonthly, setIsMonthly] = useState<boolean>(false);
  const [selectedAppIndex, setSelectedAppIndex] = React.useState(0);
  const [selectedAppIdIndex, setSelectedAppIdIndex] = React.useState(0);
  const [selectedBundleIdIndex, setSelectedBundleIdIndex] = React.useState(0);
  const [selectedPlatformIndex, setSelectedPlatformIndex] = React.useState(0);
  const [selectedCountryIndex, setSelectedCountryIndex] = React.useState(0);
  const [selectedAdUnitIndex, setSelectedAdUnitIndex] = React.useState(0);
  const [selectedAdTypeIndex, setSelectedAdTypeIndex] = React.useState(0);
  const [colorTheme, setColorTheme] = React.useState<string>("");

  const [appNames, setAppNames] = React.useState<any>(null);
  const [appIds, setAppIds] = React.useState<any>(null);
  const [bundleIds, setBundleIds] = React.useState<any>(null);
  const [platforms, setPlatforms] = React.useState<any>(null);
  const [countries, setCountries] = React.useState<any>(null);
  const [adUnits, setAdUnits] = React.useState<any>(null);
  const [adTypes, setAdTypes] = React.useState<any>(null);

  const { getIdTokenClaims } = useAuth0();

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        bodySpacing: 6, // Spacing to add to top and bottom of each tooltip item.
        //footerColor: "#fff", // color of footer text
        titleColor: colorTheme === "light" ? "#333" : "#eee", // Color of title text.
        //titleSpacing: 80, // Spacing to add to top and bottom of each title line.
        boxWidth: 12, // Width of the tooltip box.
        boxHeight: 6, // Height of the tooltip box.
        titleMarginBottom: 12,
        cornerRadius: 6,
        padding: 16,
        usePointStyle: true,
        borderWidth: 2,
        bodyFont: {
          weight: "500",
        },
        bodyColor: colorTheme === "light" ? grey_light["800"] : grey_light["800"],
        borderColor: colorTheme === "light" ? grey_light["300"] : grey_dark["300"],
        backgroundColor: colorTheme === "light" ? grey_light["50"] : grey_dark["500"],
      },
      legend: {
        position: "top" as const,
        align: "center" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle" as const,
          boxWidth: 6,
          boxHeight: 6,
          boxPadding: 20,
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
          font: {
            weight: "500",
          },
        },
      },
      title: {
        display: false,
        text: "Test Title",
      },
    },
    backgroundColor: colorTheme === "light" ? light.window_bg : dark.window_bg,
    color: colorTheme === "light" ? light.text_secondary : dark.text_secondary,
    borderColor: colorTheme === "light" ? light.window_border : dark.window_border,
    scales: {
      x: {
        type: "time",
        time: {
          unit: isMonthly ? "month" : "day",
          displayFormats: {
            day: "MMM d, yyyy",
            month: "MMM yyyy",
          },
        },
        title: {
          display: true,
          text: "DATE",
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
          font: {
            weight: "bold",
          },
          padding: {
            top: 16,
          },
        },
        ticks: {
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
        },
        grid: {
          color: colorTheme === "light" ? grey_light["400"] : grey_dark["400"],
        },
      },
      count: {
        type: "linear",
        position: "left",
        title: {
          text: "COUNT",
          display: true,
          font: {
            weight: "bold",
          },
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
        },
        ticks: {
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
        },
        grid: {
          color: colorTheme === "light" ? grey_light["400"] : grey_dark["400"],
        },
      },
      revenue: {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "REVENUE (US$)",
          font: {
            weight: "bold",
          },
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
        },
        ticks: {
          color: colorTheme === "light" ? grey_light["800"] : grey_light["600"],
        },
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
  };

  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      yAxisID: string;
    }[];
  }>({
    labels: [],
    datasets: [
      {
        label: "Revenue",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        yAxisID: "revenue",
      },
    ],
  });

  const [filters, setFilters] = useState<FilterOptions>({
    app_name: "All",
    app_id: "All",
    bundle_id: "All",
    platform: "All",
    country: "All",
    ad_unit: "All",
    ad_type: "All",
  });

  const handleColorThemeToggle = () => {
    const colorBeforeColorToggleEffect = localStorage.getItem("color-theme");
    const nextMode = colorBeforeColorToggleEffect === "dark" ? "light" : "dark";
    setColorTheme(nextMode);
  };

  const getChartsData = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    try {
      const response = await toGetPublisherCharts(4, id_token || "");
      const processedData = processData(response.data, filters, isMonthly);
      setChartData(processedData);

      setAppNames(extractUniqueValues(response.data, "app_name"));
      setAppIds(extractUniqueValues(response.data, "app_id"));
      setBundleIds(extractUniqueValues(response.data, "bundle_id")); // TODO: fix this for new structure
      setPlatforms(extractUniqueValues(response.data, "platform")); // TODO: fix this for new structure
      setCountries(extractUniqueValues(response.data, "country"));
      setAdUnits(extractUniqueValues(response.data, "ad_unit"));
      setAdTypes(extractUniqueValues(response.data, "ad_type"));

      setError(null);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChartsData();
    if (colorTheme === "") {
      const currentTheme = localStorage.getItem("color-theme");
      setColorTheme(currentTheme || "light");
    }
    document.getElementById("color-mode-toggle")?.addEventListener("click", handleColorThemeToggle);
    return () => {
      document
        .getElementById("color-mode-toggle")
        ?.removeEventListener("click", handleColorThemeToggle);
    };
  }, [filters, isMonthly]);

  const handleFilterChange = (value: string, key: string) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const hasAdUnits = adUnits && adUnits.length > 0 && adUnits[0] !== null;

  return (
    <div className="PublisherAppsAnalytics">
      <H5 className="mb-4 mt-4">Publisher Apps Analytics for Account ID: {accountId}</H5>
      {!loading && !error && (
        <div className="flex w-full justify-end mb-4">
          <div className="w-[300px]">
            <RadioTabs
              items={periodTypes}
              selectedIndex={isMonthly ? 1 : 0}
              handleIndexSelection={(index: number) =>
                index === 0 ? setIsMonthly(false) : setIsMonthly(true)
              }
            />
          </div>
        </div>
      )}

      {error && (
        <Window>
          <PTiny className="mr-4">An error has ocurred white retrieving the data:</PTiny>
          <Separator />
          <PTiny className="mr-4">{error}</PTiny>
        </Window>
      )}

      {!error && loading && (
        <Window className="flex mt-16 flex-column justify-center items-center">
          <PTiny className="mr-4">Loading reports data...</PTiny>
          <Spinner color="blue" radius={25} />
        </Window>
      )}

      {!error && !loading && colorTheme !== "" && (
        <Window className="p-0">
          <div className="grid gap-2 mb-6 grid-cols-12 p-8 pt-6 border-b border-window">
            <div className="col-span-3">
              <PTiny isMedium className="mb-2">
                App Name:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedAppIndex(index);
                  // @ts-ignore
                  handleFilterChange(appNames[index], "app_name");
                }}
                selectedIndex={selectedAppIndex}
                options={appNames.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>

            <div className="col-span-1">
              <PTiny isMedium className="mb-2">
                App ID:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedAppIdIndex(index);
                  // @ts-ignore
                  handleFilterChange(appIds[index], "app_id");
                }}
                selectedIndex={selectedAppIdIndex}
                options={appIds.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>

            <div className="col-span-3">
              <PTiny isMedium className="mb-2">
                Bundle ID:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedBundleIdIndex(index);
                  // @ts-ignore
                  handleFilterChange(bundleIds[index], "bundle_id");
                }}
                selectedIndex={selectedBundleIdIndex}
                options={bundleIds.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>

            <div className="col-span-1">
              <PTiny isMedium className="mb-2">
                Platform:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedPlatformIndex(index);
                  // @ts-ignore
                  handleFilterChange(platforms[index], "platform");
                }}
                selectedIndex={selectedPlatformIndex}
                options={platforms.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>

            <div className="col-span-1">
              <PTiny isMedium className="mb-2">
                Country:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedCountryIndex(index);
                  // @ts-ignore
                  handleFilterChange(countries[index], "country");
                }}
                selectedIndex={selectedCountryIndex}
                options={countries.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>

            <div className="col-span-1">
              <PTiny isMedium className="mb-2">
                Ad Unit:{" "}
              </PTiny>
              {hasAdUnits ? (
                <Select
                  handleIndexSelection={(index: number) => {
                    setSelectedAdUnitIndex(index);
                    // @ts-ignore
                    handleFilterChange(adUnits[index], "ad_unit");
                  }}
                  selectedIndex={selectedAdUnitIndex}
                  options={adUnits.map((name: string) => ({
                    label: name,
                    value: name,
                  }))}
                />
              ) : (
                <div className="pointer-events-none opacitry-20">
                  <TextInput defaultValue="" placeholder="n.a." />
                </div>
              )}
            </div>

            <div className="col-span-2">
              <PTiny isMedium className="mb-2">
                Ad Type:{" "}
              </PTiny>
              <Select
                handleIndexSelection={(index: number) => {
                  setSelectedAdTypeIndex(index);
                  // @ts-ignore
                  handleFilterChange(adTypes[index], "ad_type");
                }}
                selectedIndex={selectedAdTypeIndex}
                options={adTypes.map((name: string) => ({
                  label: name,
                  value: name,
                }))}
              />
            </div>
          </div>
          <div className="p-4 pt-0">
            <Line data={chartData} options={chartOptions} />
          </div>
        </Window>
      )}
    </div>
  );
};

export default PublisherAppsAnalytics;
