import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import Spinner from "@tempoplatform/tpds/components/spinner";
import tokens from "@tempoplatform/tpds/tokens";
const blueColorPalette = tokens.colors.color_palettes.blue;

const Loading = () => {
  return (
    <div className="w-full flex justify-center items-center gap-x-2 mt-[6vh]">
      <PSmall isMedium className="w-auto inline-flex animate-pulse">
        Loading...
      </PSmall>
      <Spinner color={blueColorPalette["500"]} radius={20} className="w-auto inline-flex" />
    </div>
  );
};
export default Loading;
