import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Placement, UVCoordinate } from "./types/types";
import { PlacementType_Enum } from "./types/enums";
import { PlacementsCampaign, PlacementCoordinateUpdate } from "./types/interfaces";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

export const initialState: PlacementsCampaign = {
  placements: [],
};

const placementsCreateSlice = createSlice({
  name: "placementsCreate",
  initialState,
  reducers: {
    resetState: () => initialState,
    addPlacement: state => {
      state.placements!.push({
        type: PlacementType_Enum.QUAD,
        texture_map_size: 0,
        custom_name: "",
        background_color: "#8a8a8a",
        placement_coordinates: [],
        id: uuidv4(),
        width: 0,
        height: 0,
      });
    },

    loadPlacements: (state, action: PayloadAction<any>) => {
      console.log("LOADING placements");
      console.log(state.placements);
      console.log(action.payload);
      state.placements = action.payload;
    },
    removePlacement: (state, action: PayloadAction<Placement>) => {
      console.log("REMOVING placement id", action.payload.id);
      state.placements = state.placements?.filter(placement => placement.id !== action.payload.id);
    },
    addUVCoordinate: (state, action: PayloadAction<Placement>) => {
      console.log("action", action);
      console.log("state.placements", state.placements);

      state.placements = state.placements?.map(placement => {
        if (placement.id === action.payload.id) {
          placement.placement_coordinates?.push({
            id: uuidv4(),
            semantic_version: "",
            orientation: "",
            start_x: 0,
            start_y: 0,
            end_x: 0,
            end_y: 0,
          });
        }
        return placement;
      });
    },
    removeUVCoordinate: (state, action: PayloadAction<PlacementCoordinateUpdate>) => {
      state.placements = state.placements?.map(placement => {
        if (placement.id === action.payload.placement_id) {
          // @ts-ignore
          placement.placement_coordinates = placement.placement_coordinates?.filter(
            coordinate => coordinate.id !== action.payload.coordinate.id,
          );
        }
        return placement;
      });
    },
    updatePlacement: (state, action: PayloadAction<Placement>) => {
      state.placements = state.placements?.map(placement => {
        if (placement.id === action.payload.id) {
          return action.payload;
        }
        return placement;
      });
    },
    updateUVCoordinate: (state, action: PayloadAction<PlacementCoordinateUpdate>) => {
      state.placements = state.placements?.map(placement => {
        if (placement.id === action.payload.placement_id) {
          // @ts-ignore
          placement.placement_coordinates = placement.placement_coordinates?.map(coordinate => {
            if (coordinate.id === action.payload.coordinate.id) {
              return action.payload.coordinate;
            }
            return coordinate;
          });
        }
        return placement;
      });
    },
  },
});

export const {
  resetState,
  loadPlacements,
  addPlacement,
  addUVCoordinate,
  removeUVCoordinate,
  updateUVCoordinate,
  updatePlacement,
  removePlacement,
} = placementsCreateSlice.actions;
export default placementsCreateSlice.reducer;
