import Loading from "../loading/Loading";
import React, { useEffect, useState } from "react";
import Select from "@tempoplatform/tpds/components/select";
import Steps from "@tempoplatform/tpds/components/steps";
import TopBar from "./subcomponents/TopBar";
import { PageRoot } from "@tempoplatform/tpds/elements/layout";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import {
  getCampaignEnums,
  getPlacementCampaign,
  getPlacementGroups,
  getPlacements,
  patchPlacementCampaignIsActive,
  toPostPlacementCampaign,
} from "../api/api";
import { FormLabelTiny, PLarge, Red } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { normaliseWH, uvToNormalisedWH } from "../../shared/CalculateImageAssetSize";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import SelectMulti from "@tempoplatform/tpds/components/select-multi";

import { PlacementImageUploader } from "./subcomponents/PlacementImageUploader";
import DateRangePicker from "@tempoplatform/tpds/components/date-range-picker";
import moment from "moment";
import { resetState } from "./reducer";
import Callout from "@tempoplatform/tpds/components/callout";

interface Props {
  accountId: number;
}

// @ts-ignore
function disabledDate(current) {
  return current && current < moment().endOf("day");
}

const getDateRangeValue = (startEndDates: any) => {
  if (!startEndDates) return null;
  if (!startEndDates[0]) return null;
  if (!startEndDates[1]) return null;
  const dateStart = moment(startEndDates[0], "YYYY-MM-DD");
  if (!dateStart.isValid()) return null;
  const dateEnd = moment(startEndDates[1], "YYYY-MM-DD");
  if (!dateEnd.isValid()) return null;
  return [dateStart, dateEnd];
};

const PlacementCampaignCreate: React.FC<Props> = ({ accountId }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();

  const [placementGroups, setPlacementGroups] = useState<any>(null);
  const [placementGroupOptions, setPlacementGroupOptions] = useState<any>(null);
  const [enumData, setEnumData] = useState<any>(null);

  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<any>(null);
  const [selectedLocationsIndexes, setSelectedLocationsIndexes] = useState<any>([]);

  const [loading, setLoading] = useState<any>(true);
  const [error, setError] = useState<any>(null);

  const startEndDates = useAppSelector(state => state.campaignCreate.start_end_dates);
  const dateRangeValue = getDateRangeValue(startEndDates);

  const [campaignId, setCampaignId] = useState<number>();
  const [placementGroupId, setPlacementGroupId] = useState<number>();
  const [campaignName, setCampaignName] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date("2022-03-28")); // this is using test data. TODO: apply current date + 1 month when we start using real data
  const [endDate, setEndDate] = useState(new Date("2022-04-09"));
  const [isActive, setIsActive] = useState<boolean>(false);
  // const [campaignLocations, setCampaignLocations] = useState<String[]>([]);

  const [selectedIndexes, setSelectedIndexes] = useState([0, 1]);

  const [assets, setAssets] = useState<any[]>([]);

  const steps = [
    {
      title: "Details",
    },
    {
      title: "Images",
    },
    {
      title: "Delivery",
    },
  ];

  const [currentStep, setCurrentStep] = useState<number>(0);

  type PlacementCampaignParams = {
    id: string;
  };
  let { id } = useParams<PlacementCampaignParams>();

  useEffect(() => {
    // ensure we only call getApps if we are at the appropriate path
    if (location.pathname === "/") {
      history.push("/campaigns/placement/create");
    } else {
      callGetPlacementGroups();
      setLoading(false);
    }
  }, [accountId]);

  // useEffect(() => {
  // let isValid = true
  // const filteredAssets = onlyLatestCampaignAssets(assets);
  // if(!selectedGroup){
  //   return
  // }
  // if(filteredAssets.length != selectedGroup.length){
  //   isValid = false
  // }

  // for(var i=0;i<filteredAssets.length; i++){
  //   if(!filteredAssets[i].url){
  //     isValid = false
  //   }
  // }

  // if(isValid) setCurrentStep(2)

  // }, [selectedGroup])

  const callGetPlacementGroups = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    try {
      const response = await getPlacementGroups(id_token);
      var placementsGroupOptions: any[] = [];
      response.map((placement: any, index: number) => {
        placementsGroupOptions.push({ label: placement.app_name, value: placement.id });
      });
      setPlacementGroupOptions(placementsGroupOptions);
      setPlacementGroups(response);

      const receivedEnumsData = await getCampaignEnums(id_token);
      setEnumData(receivedEnumsData.data);

      if (id) {
        setLoading(true);
        setCampaignId(parseInt(id));
        const campaignData = await getPlacementCampaign(parseInt(id), id_token);
        setPlacementGroupId(campaignData.placement_group_id);
        callGetPlacementGroup(campaignData.placement_group_id);
        setCampaignName(campaignData.name);
        setIsActive(campaignData.is_active);
        setBrandName(campaignData.brand_name);
        setStartDate(new Date(campaignData.start_date));
        setEndDate(new Date(campaignData.end_date));
        setAssets(campaignData.assets);
        let locationIndexes = [];
        for (var i = 0; i < receivedEnumsData.data.location.length; i++) {
          for (var j = 0; j < campaignData.campaign_locations.length; j++) {
            if (receivedEnumsData.data.location[i].id === campaignData.campaign_locations[j]) {
              locationIndexes.push(i);
            }
          }
        }
        setSelectedLocationsIndexes(locationIndexes);

        for (i = 0; i < placementsGroupOptions.length; i++) {
          if (placementsGroupOptions[i].value === campaignData.placement_group_id) {
            setSelectedGroupIndex(i);
          }
        }

        if (campaignData.assets.length > 0) {
          setCurrentStep(1);
        }
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
      throw new Error(error);
    }
  };
  const callGetPlacementGroup = async (groupId: number) => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    const response = await getPlacements(groupId, id_token);
    setSelectedGroup(response);
  };

  const getPlacementSize = (placement: any, index: number) => {
    let value = "";
    if (assets.length) {
      for (var i = 0; i < assets.length; i++) {
        if (assets[i].placement_id === placement.id) {
          value = assets[i].url;
        }
      }
    }

    if (placement.type == "QUAD") {
      const size: any = normaliseWH(placement.width, placement.height);
      if (currentStep === 2) {
        return (
          <div key={index} className="mt-6">
            <FormLabelTiny className="!mb-2 !mt-4">
              {placement.custom_name} Width: {size.width}, Height: {size.height} ({placement.type})
            </FormLabelTiny>
            <img style={{ maxHeight: 80 }} src={value} />
          </div>
        );
      }
      return (
        <div key={index} className="mt-6">
          <PlacementImageUploader
            campaignId={campaignId}
            placement={placement}
            size={size}
            value={value}
          />
        </div>
      );
    }

    if (placement.type == "MAPPED") {
      const coords: any = placement.placement_coordinates[0];
      const size: any = uvToNormalisedWH(
        coords.start_x,
        coords.start_y,
        coords.end_x,
        coords.end_y,
        coords.orientation,
        placement.texture_map_size,
      );
      if (currentStep === 2) {
        return (
          <div key={index} className="mt-6">
            <FormLabelTiny className="!mb-2 !mt-4">
              {placement.custom_name} Width: {size.width}, Height: {size.height} ({placement.type})
            </FormLabelTiny>
            <img style={{ maxHeight: 80 }} src={value} />
          </div>
        );
      }
      return (
        <div key={index} className="mt-6">
          <PlacementImageUploader
            campaignId={campaignId}
            placement={placement}
            size={size}
            value={value}
          />
        </div>
      );
    }
  };
  const createCampaign = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    let campaignLocations = [];
    for (var i = 0; i < selectedLocationsIndexes.length; i++) {
      const index: number = Number(selectedLocationsIndexes[i]);
      campaignLocations.push(enumData.location[index].id);
    }
    const data = {
      placement_group_id: placementGroupId,
      name: campaignName,
      brand_name: brandName,
      start_date: startDate,
      end_date: endDate,
      campaign_locations: campaignLocations,
    };
    try {
      const response = await toPostPlacementCampaign(data, id_token);
      // console.log(response);
      if (response.campaign_id) {
        setCampaignId(response.campaign_id);
        setCurrentStep(1);
        history.push("/campaigns/placement/edit/" + response.campaign_id);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };
  const onlyLatestCampaignAssets = (assets: any[]) => {
    assets.reverse();
    let placement_ids: number[] = [];
    assets = assets.filter(function (item, pos) {
      let valid = placement_ids.indexOf(item.placement_id) === -1;
      placement_ids.push(item.placement_id);
      return valid;
    });
    return assets;
  };
  const confirmCampaign = async () => {
    // console.log("confirmCampaign");
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    const campaignData = await getPlacementCampaign(parseInt(String(campaignId)), id_token);
    const groupData = await getPlacements(campaignData.placement_group_id, id_token);
    let isValid = true;

    const filteredAssets = onlyLatestCampaignAssets(campaignData.assets);

    if (filteredAssets.length !== groupData.length) {
      isValid = false;
    }

    for (var i = 0; i < filteredAssets.length; i++) {
      if (!filteredAssets[i].url) {
        isValid = false;
      }
    }

    if (isValid) setCurrentStep(2);
  };

  // render null if we aren't at the component's specific route
  if (location.pathname === "/") return null;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Callout variant="danger" title={"Error"} text={error?.response?.data?.detail} />;
  }
  if (!placementGroups) {
    return <Callout variant="danger" title={"Error"} text={"Error loading placement groups"} />;
  }
  if (!enumData || !enumData.location) {
    return <Callout variant="danger" title={"Error"} text={"Error loading location data"} />;
  }

  const activateCampaign = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    patchPlacementCampaignIsActive(parseInt(id), !isActive, id_token).then(() => {
      setIsActive(!isActive);
    });
  };

  const form1 = () => {
    return (
      <>
        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">
            <Red>*</Red> Campaign Name
          </FormLabelTiny>
          <TextInput
            type="text"
            value={campaignName}
            placeholder="Campaign Name"
            onChange={(e: any) => setCampaignName(e.target.value)}
          />
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">
            <Red>*</Red> Brand Name
          </FormLabelTiny>
          <TextInput
            type="text"
            value={brandName}
            placeholder="Brand Name"
            onChange={(e: any) => setBrandName(e.target.value)}
          />
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">Select an App</FormLabelTiny>
          <Select
            selectedIndex={selectedGroupIndex}
            placeholder="Select an App"
            labelProp="label"
            idProp="value"
            options={placementGroupOptions}
            handleIndexSelection={(index: number) => {
              setSelectedGroupIndex(index);
              setPlacementGroupId(placementGroupOptions[index].value);
              callGetPlacementGroup(placementGroupOptions[index].value);
            }}
          />
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">
            <Red>*</Red> Campaign Locations
          </FormLabelTiny>

          <SelectMulti
            options={enumData.location}
            placeholder="Select"
            labelProp="value"
            idProp="id"
            selectedIndexes={selectedLocationsIndexes}
            handleSelectionUpdate={setSelectedLocationsIndexes}
          />
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">
            <Red>*</Red> Start & End
          </FormLabelTiny>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            allowPast={false}
            onChangeStartDate={(date: Date) => setStartDate(date)}
            onChangeEndDate={(date: Date) => setEndDate(date)}
          />
        </div>

        <Button variant="secondary" onClick={createCampaign}>
          Create
        </Button>
      </>
    );
  };
  const form2 = () => {
    let campaignLocations = [];
    for (var i = 0; i < selectedLocationsIndexes.length; i++) {
      const index: number = Number(selectedLocationsIndexes[i]);
      campaignLocations.push(enumData.location[index].value);
    }
    return (
      <>
        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">Campaign Name</FormLabelTiny>
          <PLarge>{campaignName}</PLarge>
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">Brand Name</FormLabelTiny>
          <PLarge>{brandName}</PLarge>
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">App name</FormLabelTiny>
          <PLarge>{placementGroupOptions[selectedGroupIndex].label}</PLarge>
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">Campaign Locations</FormLabelTiny>
          {campaignLocations.map((item, index) => {
            return <PLarge key={index}>{item}</PLarge>;
          })}
        </div>

        <div className="w-full gap-x-1 mb-6">
          <FormLabelTiny className="mb-2">Start & End</FormLabelTiny>
          <PLarge>
            {startDate.toDateString()} - {endDate.toDateString()}
          </PLarge>
        </div>

        <Button variant="secondary" onClick={confirmCampaign}>
          Confirm
        </Button>
      </>
    );
  };
  const form3 = () => {
    return (
      <>
        <div className="w-full gap-x-1 mb-6">
          <PLarge>
            {isActive
              ? "Your campaign is currently active."
              : "Your campaign is currently inactive."}
          </PLarge>
        </div>
        <div className="w-full gap-x-1 mb-6">
          <Button variant="secondary" onClick={activateCampaign}>
            {isActive ? "Deactivate" : "Activate"}
          </Button>
        </div>
        <div className="w-full gap-x-1 mb-6">
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(resetState());
              history.push("/campaigns");
            }}
          >
            Complete Campaign
          </Button>
        </div>
      </>
    );
  };

  return (
    <PageRoot className="fixed top-0 left-0 z-30">
      <TopBar createCampaign={() => createCampaign()} campaignId={campaignId} />
      <div className="flex gap-0 w-full">
        <div
          className="flex flex-col bg-grey-dark-scale-800"
          style={{ height: "calc(100vh - 50px)", width: "550px", overflowY: "scroll" }}
        >
          <div className="w-full p-4 flex items-center bg-window border-b border-window h-[60px] flex">
            <Steps steps={steps} currentStepIndex={currentStep} />
          </div>
          <div className="flex flex-col p-6 bg-grey-dark-scale-800">
            {/* FORM 1 */}
            {currentStep === 0 && form1()}
            {/* FORM 2 */}
            {currentStep === 1 && form2()}
            {/* FORM 3 */}
            {currentStep === 2 && form3()}
          </div>
        </div>
        <div className="overflow-y-scroll w-full px-20" style={{ height: "calc(100vh - 50px)" }}>
          {currentStep === 0 && selectedGroup && (
            <>
              <PLarge className="!mb-2 !mt-4">
                "{placementGroupOptions[selectedGroupIndex].label}" contains the following
                placements:
              </PLarge>
            </>
          )}
          {selectedGroup?.map((placement: any, index: number) =>
            getPlacementSize(placement, index),
          )}
        </div>
      </div>
    </PageRoot>
  );
};
export default PlacementCampaignCreate;
