import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  updateProductReviewCount,
  updateProductReviewOverallScore,
  updateProductReviewPercentRecommended,
} from "../campaignCreateSlice";
import { FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import NumberInput from "@tempoplatform/tpds/components/input/number";
import ChartBarSlider from "./ChartBarSlider";

interface Props {
  productIndex: number;
}

const ReviewsBaseInfo = (props: Props) => {
  const { productIndex } = props;
  const dispatch = useAppDispatch();
  const productReviewCount = useAppSelector(
    state => state.campaignCreate.products[productIndex].review_count,
  );
  const productReviewOverallScore = useAppSelector(
    state => state.campaignCreate.products[productIndex].review_overall_score,
  );
  const productReviewPercentRecommended = useAppSelector(
    state => state.campaignCreate.products[productIndex].review_percent_recommended,
  );

  const callUpdateProductReviewCount = (newValue: string) => {
    const parsedValue = parseInt(newValue);
    dispatch(
      updateProductReviewCount({
        productIndex: productIndex,
        value: parsedValue,
      }),
    );
  };

  const callUpdateProductReviewOverallScore = (newValue: string) => {
    const parsedValue = parseFloat(newValue);
    dispatch(
      updateProductReviewOverallScore({
        productIndex: productIndex,
        value: parsedValue,
      }),
    );
  };

  const callUpdateProductReviewPercentRecommended = (newValue: string) => {
    let parsedValue = parseFloat(newValue);
    if (parsedValue > 100) {
      parsedValue = 100;
    }
    dispatch(
      updateProductReviewPercentRecommended({
        productIndex: productIndex,
        value: parsedValue,
      }),
    );
  };

  return (
    <div>
      <div className="flex gap-2 mb-4">
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Review count
          </FormLabelSmall>
          <NumberInput
            placeholder="Any number"
            allowFloats={false}
            initialValue={productReviewCount?.toString() || undefined}
            onChangeCallback={(value: any) => callUpdateProductReviewCount(value)}
          />
        </div>
        <div>
          <FormLabelSmall className="mb-2">
            <Red>*</Red> Overall score
          </FormLabelSmall>
          <NumberInput
            maxValue={5}
            maxLength={4}
            placeholder="0 to 5"
            initialValue={productReviewOverallScore?.toString() || undefined}
            onChangeCallback={(value: any) => callUpdateProductReviewOverallScore(value)}
          />
        </div>
        <div>
          <FormLabelSmall className="mb-2">% recommend</FormLabelSmall>
          <NumberInput
            maxValue={100}
            placeholder="0 to 100"
            allowFloats={true}
            floatPrecision={1}
            initialValue={productReviewPercentRecommended?.toString() || undefined}
            onChangeCallback={(value: any) => callUpdateProductReviewPercentRecommended(value)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-4">
        <ChartBarSlider productIndex={productIndex} barNumber={5} />
        <ChartBarSlider productIndex={productIndex} barNumber={4} />
        <ChartBarSlider productIndex={productIndex} barNumber={3} />
        <ChartBarSlider productIndex={productIndex} barNumber={2} />
        <ChartBarSlider productIndex={productIndex} barNumber={1} />
      </div>
    </div>
  );
};

export default ReviewsBaseInfo;
