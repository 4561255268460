import { Upload } from "antd";
import React, { useState } from "react";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setBrandName,
  setCampaignName,
  setHeadline,
  setLogoImage,
  setMainImage,
  setRedirectUrl,
  setVideo,
  updateAdType,
  updateCtaLabel,
  updateRewardedInterstitial,
} from "./campaignCreateSlice";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { FormLabelTiny, PLarge, PSmall, Red } from "@tempoplatform/tpds/elements/typography";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { Separator, Window } from "@tempoplatform/tpds/elements/layout";
import IslandStyleConfigurator from "./sub_components/IslandStyleConfigurator";
import ImagesFormAdTypeConfigurator from "./sub_components/ImagesFormAdTypeConfigurator";
import RadioTabs from "@tempoplatform/tpds/components/radiotabs";
import { getFileExtension } from "../helpers/string";

import AWS from "aws-sdk";

// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import CloudUploadDisplay from "../../shared/CloudUploadDisplay";
import {
  REACT_APP_AWS_ACCESS_ID,
  REACT_APP_AWS_ACCESS_KEY,
  REACT_APP_AWS_CLOUDFRONT_URL,
  REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_AWS_S3_REGION,
} from "../helpers/getS3Config";
import AdTypes from "../../constants/ad_types";

//AWS config
AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
  region: REACT_APP_AWS_S3_REGION,
});

const adTypeItems = [
  { value: AdTypes.shoppable, id: AdTypes.shoppable },
  { value: AdTypes.brand_awareness, id: AdTypes.brand_awareness },
  { value: AdTypes.images_form, id: AdTypes.images_form },
  { value: AdTypes.redirect_to_webpage, id: AdTypes.redirect_to_webpage },
];

const { Dragger } = Upload;

const rewardedInterstitialTypes = ["Rewarded", "Interstitial"];
const rewardedInterstitialItems = [
  { value: rewardedInterstitialTypes[0], id: rewardedInterstitialTypes[0] },
  { value: rewardedInterstitialTypes[1], id: rewardedInterstitialTypes[1] },
];

const MainAd = () => {
  const dispatch = useAppDispatch();
  const [isMainImageUploading, setIsMainImageUploading] = useState(false);
  const [isLogoImageUploading, setIsLogoImageUploading] = useState(false);
  const mainImage = useAppSelector(state => state.campaignCreate.mainImage);
  const logoImage = useAppSelector(state => state.campaignCreate.logoImage);
  const video = useAppSelector(state => state.campaignCreate.video);
  const brandName = useAppSelector(state => state.campaignCreate.brandName);
  const headline = useAppSelector(state => state.campaignCreate.headline);
  const redirectUrl = useAppSelector(state => state.campaignCreate.redirect_url);
  const campaignName = useAppSelector(state => state.campaignCreate.campaignName);
  const ctaLabel = useAppSelector(state => state.campaignCreate.cta_label);
  const adTypeState = useAppSelector(state => state.campaignCreate.ad_type);
  const rewardedInterstitialState = useAppSelector(
    state => state.campaignCreate.rewarded_interstitial,
  );

  // @ts-ignore
  const selectedAdTypeIndex = adTypeItems.findIndex(adType => adType.value === adTypeState) || 0;
  const selectedAdTypeName = adTypeItems[selectedAdTypeIndex].value;

  const handleMainMediaUpload = async (file: any) => {
    if (!file.type.includes("image") && !file.type.includes("video")) {
      alert("Please upload an image or video file");
      return;
    }
    setIsMainImageUploading(true);
    const fileTypeSlug = file.type.includes("image") ? "images" : "videos";
    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/${fileTypeSlug}/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `${fileTypeSlug}/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();
    await upload.then((data: any) => {
      setIsMainImageUploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        if (file.type.includes("image")) {
          dispatch(setVideo(undefined));
          dispatch(setMainImage(newFileURL));
        } else if (file.type.includes("video")) {
          dispatch(setMainImage(undefined));
          dispatch(setVideo(newFileURL));
        }
      } else {
        alert("Upload failed");
      }
    });
  };

  const handleLogoImageUpload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setIsLogoImageUploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setIsLogoImageUploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(setLogoImage(newFileURL));
      } else {
        alert("Upload failed");
      }
    });
  };

  let handleMainImageRemove = () => {
    dispatch(setMainImage(undefined));
  };
  let handleLogoImageRemove = () => {
    dispatch(setLogoImage(undefined));
  };
  let handleVideoRemove = () => {
    dispatch(setVideo(undefined));
  };

  return (
    <div className="p-4">
      <PLarge className="mb-6" isBold>
        Ad Type
      </PLarge>
      <RadioTabs
        items={adTypeItems}
        selectedIndex={selectedAdTypeIndex}
        handleIndexSelection={(newIndex: number) => {
          dispatch(updateAdType(adTypeItems[newIndex].value));
        }}
      />
      <div className="h-2" />
      <RadioTabs
        items={rewardedInterstitialItems}
        selectedIndex={rewardedInterstitialTypes.indexOf(rewardedInterstitialState)}
        handleIndexSelection={(newIndex: number) => {
          dispatch(updateRewardedInterstitial(rewardedInterstitialItems[newIndex].value));
        }}
      />
      {adTypeState === AdTypes.images_form && <ImagesFormAdTypeConfigurator />}
      <PLarge isBold className="mt-8 mb-6">
        Ad Information
      </PLarge>
      <FormLabelTiny className="!mb-2">
        <Red>*</Red> Name of the campaign
      </FormLabelTiny>
      <TextInput
        className="mb-4"
        type="text"
        placeholder="Name"
        value={campaignName || ""}
        onChange={(e: any) => dispatch(setCampaignName(e.target.value))}
      />
      {selectedAdTypeName === AdTypes.brand_awareness && (
        <>
          <FormLabelTiny className="!mb-2">
            <Red>*</Red> CTA Label
          </FormLabelTiny>
          <TextInput
            className="mb-6"
            type="text"
            placeholder="CTA Label"
            value={ctaLabel || ""}
            onChange={(e: any) => dispatch(updateCtaLabel(e.target.value))}
          />
        </>
      )}

      {adTypeState !== AdTypes.redirect_to_webpage && (
        <FormLabelTiny className="mb-2">
          <Red>*</Red> Brand name shown during product purchase
        </FormLabelTiny>
      )}
      {adTypeState === AdTypes.redirect_to_webpage && (
        <FormLabelTiny className="mb-2">
          <Red>*</Red> Brand name
        </FormLabelTiny>
      )}
      <TextInput
        className="mb-6"
        type="text"
        placeholder="Brand name"
        value={brandName || ""}
        onChange={(e: any) => dispatch(setBrandName(e.target.value))}
      />
      <FormLabelTiny className="mb-2">
        <Red>*</Red> Headline to display next to logo
      </FormLabelTiny>
      <TextInput
        className="mb-6"
        type="text"
        value={headline || ""}
        placeholder="Headline"
        onChange={(e: any) => dispatch(setHeadline(e.target.value))}
      />

      {selectedAdTypeName === AdTypes.redirect_to_webpage && (
        <>
          <FormLabelTiny className="!mb-2 !mt-4">
            <Red>*</Red> Redirect URL
          </FormLabelTiny>
          <TextInput
            className="mb-6"
            type="text"
            value={redirectUrl || ""}
            placeholder="example.com"
            onChange={(e: any) => dispatch(setRedirectUrl(e.target.value))}
          />
        </>
      )}

      <FormLabelTiny className="mb-2">
        <Red>*</Red> Main video (or image)
      </FormLabelTiny>
      {!video && !mainImage && (
        <Dragger
          beforeUpload={handleMainMediaUpload}
          multiple={false}
          fileList={[]}
          className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
        >
          {isMainImageUploading && <LoadingOutlined />}
          <CloudUploadDisplay videos={true} />
        </Dragger>
      )}
      {video ? (
        <div>
          <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
            <div
              style={{
                width: "50%",
                height: "auto",
                borderRadius: "10px",
              }}
            >
              <video id="video" style={{ width: "50%", height: "auto", borderRadius: "10px" }}>
                <source type="video/mp4" src={video} />
              </video>
            </div>
            <Button className="w-[50%]" onClick={handleVideoRemove}>
              Remove video
            </Button>
          </Window>
          <br />
        </div>
      ) : null}
      {mainImage ? (
        <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
          <img
            alt="main ad"
            src={mainImage}
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "10px",
            }}
          />
          <Button className="w-[50%]" onClick={handleMainImageRemove}>
            Remove Image
          </Button>
        </Window>
      ) : null}

      <FormLabelTiny className="!mb-2 !mt-4">
        <Red>*</Red> Logo image
      </FormLabelTiny>
      {!logoImage && (
        <Dragger
          beforeUpload={handleLogoImageUpload}
          multiple={false}
          fileList={[]}
          className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
        >
          {isLogoImageUploading && <LoadingOutlined />}
          <span
            style={{
              display: "block",
              fontSize: "30px",
              lineHeight: "100%",
              marginBottom: "10px",
              opacity: "0.3",
            }}
          >
            <CloudUploadOutlined size={0} className="!text-primary" />
          </span>
          <PSmall isMedium className="leading-[130%] !text-secondary">
            Drop your image here, or <br />
            <span style={{ color: "#1990ff" }}>click to upload</span>
          </PSmall>
        </Dragger>
      )}
      {logoImage !== undefined ? (
        <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
          <img
            alt="Logo"
            src={logoImage}
            style={{
              width: "50%",
              height: "auto",
            }}
          />
          <Button className="w-[50%]" onClick={handleLogoImageRemove}>
            Remove logo
          </Button>
        </Window>
      ) : null}
      <br />
      <PLarge isBold className="mt-2">
        Ad style
      </PLarge>
      <Separator className="mt-2 !mb-4" />
      <IslandStyleConfigurator />
    </div>
  );
};
export default MainAd;
