export const hasRewardedAdType = (data: any) => {
  return data.some((item: any) => item.ad_type === "REWARDED");
};

export const hasInsterstitialAdType = (data: any) => {
  return data.some((item: any) => item.ad_type === "INTERSTITIAL");
};

export const getCsvContent = (data: any) => {
  return (
    "data:text/csv;charset=utf-8, Campaign Name, Impressions, Spend, CPM, Sessions with click, SWC (Product 1), SWC (Product 2), SWC (Product 3), SWC (Product 4)\n" +
    data
      .map(
        (e: any) =>
          `${e.campaign_name}, ${e.impressions}, ${e.spend}, ${e.cpm}, ${e.sessions_with_click}, ${e.sessions_with_click_product_0}, ${e.sessions_with_click_product_1}, ${e.sessions_with_click_product_2}, ${e.sessions_with_click_product_3}`,
      )
      .join("\n")
  );
};

export const getCampaignIdsFromUrlQuery = (campaignsQuery: any) => {
  let selectedCampaignIds: string[] = [];
  if (campaignsQuery) {
    // if found, split the string into an array of ints
    const campaignStringIds = campaignsQuery.split(/[ ,]+/);
    selectedCampaignIds = campaignStringIds.map((item: string) => item);
  }
  return selectedCampaignIds;
};

export const getUniqueCampaigns = (data: any) => {
  const getSum = (campaign_id: string, key: string) => {
    const allEntriesOfSameCampaignId = data.filter((item: any) => item.campaign_id === campaign_id);
    return allEntriesOfSameCampaignId.reduce((acc: number, curr: any) => {
      return acc + curr[key];
    }, 0);
  };
  // create new array to store all the unique campaigns names & ids
  const uniqueCampaigns = data.reduce((acc: any[], item: any) => {
    const equalItem = acc.find(element => element.campaign_name === item.campaign_name);
    const totalSpend = getSum(item.campaign_id, "spend");
    const totalImpressions = getSum(item.campaign_id, "impressions");
    const totalSessionsWithClick = getSum(item.campaign_id, "sessions_with_click");
    const totalSessionsWithClickProduct0 = getSum(
      item.campaign_id,
      "sessions_with_click_product_0",
    );
    const totalSessionsWithClickProduct1 = getSum(
      item.campaign_id,
      "sessions_with_click_product_1",
    );
    const totalSessionsWithClickProduct2 = getSum(
      item.campaign_id,
      "sessions_with_click_product_2",
    );
    const totalSessionsWithClickProduct3 = getSum(
      item.campaign_id,
      "sessions_with_click_product_3",
    );
    const finalCpm = `${(totalSpend / (1000 * totalImpressions)).toFixed(2)}`;
    if (!equalItem) {
      acc.push({
        campaign_name: item.campaign_name,
        campaign_id: item.campaign_id,
        ad_type: item.ad_type,
        spend: totalSpend,
        impressions: totalImpressions,
        cpm: finalCpm,
        sessions_with_click: totalSessionsWithClick,
        sessions_with_click_product_0: totalSessionsWithClickProduct0,
        sessions_with_click_product_1: totalSessionsWithClickProduct1,
        sessions_with_click_product_2: totalSessionsWithClickProduct2,
        sessions_with_click_product_3: totalSessionsWithClickProduct3,
      });
    }
    return acc;
  }, []);
  return uniqueCampaigns;
};

export const generateTotals = (data: any) => {
  const spend = data.reduce((acc: number, curr: any) => {
    return acc + curr.spend;
  }, 0);
  const impressions = data.reduce((acc: number, curr: any) => {
    return acc + curr.impressions;
  }, 0);
  const cpm = `${(spend / (1000 * impressions)).toFixed(2)}`;
  return {
    impressions: impressions,
    spend: spend,
    cpm: cpm,
    sessionsWithClick: data.reduce((acc: number, curr: any) => {
      return acc + curr.sessions_with_click;
    }, 0),
    swc_product1: data.reduce((acc: number, curr: any) => {
      return acc + curr.sessions_with_click_product_0;
    }, 0),
    swc_product2: data.reduce((acc: number, curr: any) => {
      return acc + curr.sessions_with_click_product_1;
    }, 0),
    swc_product3: data.reduce((acc: number, curr: any) => {
      return acc + curr.sessions_with_click_product_2;
    }, 0),
    swc_product4: data.reduce((acc: number, curr: any) => {
      return acc + curr.sessions_with_click_product_3;
    }, 0),
  };
};

export const generateMultiSelectItems = (uniqueCampaigns: any) => {
  return [
    ...uniqueCampaigns.map((item: any) => {
      return { label: item.campaign_name, value: item.campaign_id };
    }),
  ];
};

export const getSelectedIndexesForMultiSelect = (
  multiSelectItems: any[],
  selectedCampaignIds: string[],
) => {
  const campaignsSelectedIndexesForMultiSelect: number[] = [];
  multiSelectItems.map((item: any, index: number) => {
    if (selectedCampaignIds.includes(item.value)) {
      campaignsSelectedIndexesForMultiSelect.push(index);
    }
    return null;
  });
  return campaignsSelectedIndexesForMultiSelect;
};

export const getAdTypeOptions = (hasRewarded: boolean, hasInsterstitial: boolean) => {
  const adTypeOptions = [
    { label: "ALL", value: "ALL" },
    { label: "REWARDED", value: "REWARDED" },
    { label: "INTERSTITIAL", value: "INTERSTITIAL" },
  ];
  if (!hasRewarded) {
    const index = adTypeOptions.findIndex(item => item.label === "REWARDED");
    if (index > -1) {
      adTypeOptions.splice(index, 1);
    }
  }
  if (!hasInsterstitial) {
    const index = adTypeOptions.findIndex(item => item.label === "INTERSTITIAL");
    if (index > -1) {
      adTypeOptions.splice(index, 1);
    }
  }
  if (!hasRewarded || !hasInsterstitial) {
    const index = adTypeOptions.findIndex(item => item.label === "ALL");
    if (index > -1) {
      adTypeOptions.splice(index, 1);
    }
  }
  return adTypeOptions;
};
