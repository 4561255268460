import { configureStore } from "@reduxjs/toolkit";
import campaignCreateReducer from "../components/campaignCreate/campaignCreateSlice";
import placementsCreateReducer from "../components/placements/reducer";
import placementsCampaignCreateReducer from "../components/placementCampaignCreate/reducer";

export const store = configureStore({
  reducer: {
    campaignCreate: campaignCreateReducer,
    placementsCreate: placementsCreateReducer,
    placementsCampaignCreate: placementsCampaignCreateReducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
