import axios from "axios";
import { baseUrl } from "./baseUrl";

export interface ApiKey {
  id: string;
  valid_from: Date;
  valid_to: Date;
}

export interface GetAccountResponse {
  id: number;
  account_type_id: string;
  stripe_connected_account_id: string | null;
  email: string;
  api_keys: ApiKey[];
}

export const toGetAccount = async (userId: string, token: string): Promise<GetAccountResponse | null> => {
  const endPoint = `/accounts/?user_id=${userId}`;
  try {
    const response = await axios.get<GetAccountResponse>(baseUrl + endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError<GetAccountResponse>(error) && error.response) {
      if (error.response.status === 404) {
        return null;
      } else {
        throw new Error(`Failed to get account: ${error.response.status} (${error.response.statusText})`);
      }
    } else {
      throw new Error(`Failed to get account with error: ${error}`);
    }
  }
};

export const toGetApps = async (accountId: number, token: string) => {
  const endPoint = `/apps/?account_id=${accountId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const toPostOrganization = async (data: any, token: string) => {
  const endPoint = `/organization/`;
  const response = await axios.post(
    baseUrl + endPoint,
    {
      user_id: data.user_id,
      account_name: data.account_name,
      currency: data.currency,
      phone_country_code: data.phone_country_code,
      phone_number: data.phone_number,
      email: data.email,
      organization_name: data.organization_name,
      country: data.country,
      business_type: data.business_type,
      account_type: data.account_type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPostRequestApiKey = async (accountId: any, token: any) => {
  const endPoint = `/apiKey/`;
  const response = await axios.post(
    baseUrl + endPoint + `?account_id=${accountId}`,
    {
      account_id: accountId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPatchRevokeApiKey = async (accountId: string, apiKeyId: any, token: any) => {
  const endPoint = `/apiKey/`;
  const response = await axios.patch(
    baseUrl + endPoint + `?api_key_id=${apiKeyId}`,
    {
      account_id: accountId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const toPostApp = async (data: any, token: any) => {
  const endPoint = `/apps/`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const toPostCampaign = async (data: any, token: any) => {
  const endPoint = `/campaigns/`;
  if (!data.form_properties) {
    data.form_properties = [];
  }
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getCampaignEnums = async (token: any) => {
  const endPoint = `${baseUrl}/campaignsEnums`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const getBusinessTypeEnums = async (token: any) => {
  const endPoint = `${baseUrl}/accountEnums`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response.data;
  }
};

export const getCampaigns = async (token: string, accountId: number) => {
  const endPoint = `${baseUrl}/campaigns?account_id=${accountId}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response.data;
  }
};

export const getCampaignDetails = async (campaignId: number, token: string | undefined) => {
  const endPoint = `${baseUrl}/campaigns/${campaignId}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const toGetPublisherCharts = async (weeksOfData: number, token: string) => {
  const endPoint = `${baseUrl}/charts/publisher?weeks_of_data=${weeksOfData}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const toGetAdvertiserCharts = async (weeksOfData: number, token: string | null) => {
  if (!token)
    return console.log("toGetAdvertiserCharts ::: Access token required but not provided");
  const endPoint = `${baseUrl}/charts/advertiser?weeks_of_data=${weeksOfData}`;
  const response = await axios
    .get(endPoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.log("err", err);
      return err;
    });

  if (response) {
    return response;
  }
};

export const patchCampaignIsActive = async (campaignId: number, isActive: boolean) => {
  console.log("We are not allowing this action at this time.");
};

export enum CampaignState {
  ENDED = "ENDED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  UPCOMING = "UPCOMING",
}

export interface CampaignListItem {
  id: number;
  name: string;
  delivery_status: string;
  is_active: boolean;
  start_date: string;
  end_date: string;
  ad_type?: string;
  is_interstitial?: boolean;
  budget?: number;
  cpm?: number;
  key?: number;
  state?: CampaignState;
}

export const getPlacementGroups = async (token: any) => {
  const endPoint = `/placementGroups`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
export const getPlacementGroup = async (appId: number, token: any) => {
  const endPoint = `/placementGroup/${appId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
export const getPlacements = async (placementGroupId: number, token: any) => {
  const endPoint = `/placements/${placementGroupId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
export const toPostPlacement = async (appId: number, data: any, token: any) => {
  const endPoint = `/placements/${appId}`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const toPostPlacementCampaign = async (data: any, token: any) => {
  const endPoint = `/brandedCampaign`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getPlacementCampaigns = async (token: any) => {
  const endPoint = `/brandedCampaigns`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getPlacementCampaign = async (placementCampaignId: number, token: any) => {
  const endPoint = `/brandedCampaigns/${placementCampaignId}`;
  const response = await axios.get(baseUrl + endPoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const toPostPlacementCampaignAsset = async (
  placementCampaignId: number,
  data: any,
  token: any,
) => {
  const endPoint = `/brandedCampaignAssets/${placementCampaignId}`;
  const response = await axios.post(baseUrl + endPoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const patchPlacementCampaignIsActive = async (
  placementCampaignId: number,
  isActive: boolean,
  token: any,
) => {
  console.log("We are not allowing this action at this time.");
  const endPoint = `/brandedCampaign/isActive/${placementCampaignId}?is_active=${isActive}`;
  const data = new FormData();
  data.append("branded_campaign_id", String(placementCampaignId));
  data.append("is_active", String(isActive));

  const response = await axios.patch(
    baseUrl + endPoint,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};
