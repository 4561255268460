import styled from "@emotion/styled";
import React, { useState } from "react";
import IMAGE from "./Product";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Upload } from "antd";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { PNano, FormLabelTiny, Red, PSmall, PLarge } from "@tempoplatform/tpds/elements/typography";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { Window, Separator } from "@tempoplatform/tpds/elements/layout";
import { getFileExtension } from "../helpers/string";
import AWS from "aws-sdk";

// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import {
  setRedirectUrl,
  setRedirectImage0,
  setRedirectImage1,
  setRedirectImage2,
  setRedirectImage3,
  setRedirectUrl0,
  setRedirectUrl1,
  setRedirectUrl2,
  setRedirectUrl3,
} from "./campaignCreateSlice";
import clsx from "clsx";

import CloudUploadDisplay from "../../shared/CloudUploadDisplay";
import {
  REACT_APP_AWS_CLOUDFRONT_URL,
  REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_AWS_S3_REGION,
  REACT_APP_AWS_ACCESS_ID,
  REACT_APP_AWS_ACCESS_KEY,
} from "../helpers/getS3Config";

const { Dragger } = Upload;

//AWS config
AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
  region: REACT_APP_AWS_S3_REGION,
});

const RootDiv = styled.div`
  .ant-form-item {
    margin: 0px;
  }
`;

const Products = () => {
  const dispatch = useAppDispatch();
  const [openTab, setOpenTab] = React.useState<number>(1);

  const cState = useAppSelector(state => state.campaignCreate);
  const redirectUrl0 = cState.redirect_to_webpage_url_0;
  const redirectUrl1 = cState.redirect_to_webpage_url_1;
  const redirectUrl2 = cState.redirect_to_webpage_url_2;
  const redirectUrl3 = cState.redirect_to_webpage_url_3;

  const redirectImage0 = cState.redirect_to_webpage_image_0;
  const [isRedirectImage0uploading, setisRedirectImage0uploading] = useState(false);
  const handleRedirectImage0Upload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setisRedirectImage0uploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setisRedirectImage0uploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(setRedirectImage0(newFileURL));
      } else {
        alert("Upload failed");
      }
    });
  };
  let handleRedirectImage0Remove = () => {
    dispatch(setRedirectImage0(""));
  };

  const redirectImage1 = cState.redirect_to_webpage_image_1;
  const [isRedirectImage1uploading, setisRedirectImage1uploading] = useState(false);
  const handleRedirectImage1Upload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setisRedirectImage1uploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setisRedirectImage1uploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(setRedirectImage1(newFileURL));
      } else {
        alert("Upload failed");
      }
    });
  };
  let handleRedirectImage1Remove = () => {
    dispatch(setRedirectImage1(undefined));
  };

  const redirectImage2 = cState.redirect_to_webpage_image_2;
  const [isRedirectImage2uploading, setisRedirectImage2uploading] = useState(false);
  const handleRedirectImage2Upload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setisRedirectImage2uploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setisRedirectImage2uploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(setRedirectImage2(newFileURL));
      } else {
        alert("Upload failed");
      }
    });
  };
  let handleRedirectImage2Remove = () => {
    dispatch(setRedirectImage2(undefined));
  };

  const redirectImage3 = cState.redirect_to_webpage_image_3;
  const [isRedirectImage3uploading, setisRedirectImage3uploading] = useState(false);
  const handleRedirectImage3Upload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setisRedirectImage3uploading(true);

    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setisRedirectImage3uploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        dispatch(setRedirectImage3(newFileURL));
      } else {
        alert("Upload failed");
      }
    });
  };
  let handleRedirectImage3Remove = () => {
    dispatch(setRedirectImage3(undefined));
  };

  const handleOpenTab = (tab: number) => {
    setOpenTab(tab - 1);
  };

  const baseTabClass = clsx(
    `w-[25%] py-3`,
    `flex items-center justify-center cursor-pointer`,
    `bg-grey-light-scale-200 dark:bg-black`,
    `select-none`,
  );

  return (
    <RootDiv>
      <div className="p-2 px-4">
        <div className="flex flex-col gap-2">
          <div style={openTab >= 0 ? {} : { display: "none" }}>
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> Image 1
            </FormLabelTiny>
            {!redirectImage0 && (
              <Dragger
                beforeUpload={handleRedirectImage0Upload}
                multiple={false}
                fileList={[]}
                className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
              >
                {isRedirectImage0uploading && <LoadingOutlined />}
                <span
                  style={{
                    display: "block",
                    fontSize: "30px",
                    lineHeight: "100%",
                    marginBottom: "10px",
                    opacity: "0.3",
                  }}
                >
                  <CloudUploadOutlined size={0} className="!text-primary" />
                </span>
                <PSmall isMedium className="leading-[130%] !text-secondary">
                  Drop your image here, or <br />
                  <span style={{ color: "#1990ff" }}>click to upload</span>
                </PSmall>
              </Dragger>
            )}
            {redirectImage0 !== "" ? (
              <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
                <img
                  alt="Image 1"
                  src={redirectImage0}
                  style={{
                    width: "50%",
                    height: "auto",
                  }}
                />
                <Button className="w-[50%]" onClick={handleRedirectImage0Remove}>
                  Remove Image 1
                </Button>
              </Window>
            ) : null}
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> URL 1
            </FormLabelTiny>
            <TextInput
              className="mb-4"
              type="text"
              placeholder="example.com"
              defaultValue={redirectUrl0 || ""}
              onChange={(e: any) => dispatch(setRedirectUrl0(e.target.value))}
            />
          </div>
          <div style={openTab >= 1 ? {} : { display: "none" }}>
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> Image 2
            </FormLabelTiny>
            {!redirectImage1 && (
              <Dragger
                beforeUpload={handleRedirectImage1Upload}
                multiple={false}
                fileList={[]}
                className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
              >
                {isRedirectImage1uploading && <LoadingOutlined />}
                <span
                  style={{
                    display: "block",
                    fontSize: "30px",
                    lineHeight: "100%",
                    marginBottom: "10px",
                    opacity: "0.3",
                  }}
                >
                  <CloudUploadOutlined size={0} className="!text-primary" />
                </span>
                <PSmall isMedium className="leading-[130%] !text-secondary">
                  Drop your image here, or <br />
                  <span style={{ color: "#1990ff" }}>click to upload</span>
                </PSmall>
              </Dragger>
            )}
            {redirectImage1 !== "" ? (
              <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
                <img
                  alt="Image 2"
                  src={redirectImage1}
                  style={{
                    width: "50%",
                    height: "auto",
                  }}
                />
                <Button className="w-[50%]" onClick={handleRedirectImage1Remove}>
                  Remove Image 2
                </Button>
              </Window>
            ) : null}
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> URL 2
            </FormLabelTiny>
            <TextInput
              className="mb-4"
              type="text"
              placeholder="example.com"
              defaultValue={redirectUrl1 || ""}
              onChange={(e: any) => dispatch(setRedirectUrl1(e.target.value))}
            />
          </div>
          <div style={openTab >= 2 ? {} : { display: "none" }}>
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> Image 3
            </FormLabelTiny>
            {!redirectImage2 && (
              <Dragger
                beforeUpload={handleRedirectImage2Upload}
                multiple={false}
                fileList={[]}
                className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
              >
                {isRedirectImage2uploading && <LoadingOutlined />}
                <span
                  style={{
                    display: "block",
                    fontSize: "30px",
                    lineHeight: "100%",
                    marginBottom: "10px",
                    opacity: "0.3",
                  }}
                >
                  <CloudUploadOutlined size={0} className="!text-primary" />
                </span>
                <PSmall isMedium className="leading-[130%] !text-secondary">
                  Drop your image here, or <br />
                  <span style={{ color: "#1990ff" }}>click to upload</span>
                </PSmall>
              </Dragger>
            )}
            {redirectImage2 !== "" ? (
              <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
                <img
                  alt="Image 3"
                  src={redirectImage2}
                  style={{
                    width: "50%",
                    height: "auto",
                  }}
                />
                <Button className="w-[50%]" onClick={handleRedirectImage2Remove}>
                  Remove Image 3
                </Button>
              </Window>
            ) : null}
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> URL 3
            </FormLabelTiny>
            <TextInput
              className="mb-4"
              type="text"
              placeholder="example.com"
              defaultValue={redirectUrl2 || ""}
              onChange={(e: any) => dispatch(setRedirectUrl2(e.target.value))}
            />
          </div>
          <div style={openTab >= 3 ? {} : { display: "none" }}>
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> Image 4
            </FormLabelTiny>
            {!redirectImage3 && (
              <Dragger
                beforeUpload={handleRedirectImage3Upload}
                multiple={false}
                fileList={[]}
                className="!bg-window !border-grey-light-scale-400 dark:!border-grey-dark-scale-200"
              >
                {isRedirectImage3uploading && <LoadingOutlined />}
                <span
                  style={{
                    display: "block",
                    fontSize: "30px",
                    lineHeight: "100%",
                    marginBottom: "10px",
                    opacity: "0.3",
                  }}
                >
                  <CloudUploadOutlined size={0} className="!text-primary" />
                </span>
                <PSmall isMedium className="leading-[130%] !text-secondary">
                  Drop your image here, or <br />
                  <span style={{ color: "#1990ff" }}>click to upload</span>
                </PSmall>
              </Dragger>
            )}
            {redirectImage3 !== "" ? (
              <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
                <img
                  alt="Image 4"
                  src={redirectImage3}
                  style={{
                    width: "50%",
                    height: "auto",
                  }}
                />
                <Button className="w-[50%]" onClick={handleRedirectImage3Remove}>
                  Remove Image 4
                </Button>
              </Window>
            ) : null}
            <FormLabelTiny className="!mb-2 !mt-4">
              <Red>*</Red> URL 4
            </FormLabelTiny>
            <TextInput
              className="mb-4"
              type="text"
              placeholder="example.com"
              defaultValue={redirectUrl3 || ""}
              onChange={(e: any) => dispatch(setRedirectUrl3(e.target.value))}
            />
          </div>

          {openTab < 3 && (
            <Button onClick={() => handleOpenTab(openTab + 2)}>Add another Image</Button>
          )}
        </div>
      </div>
    </RootDiv>
  );
};
export default Products;
