import React, { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import PublisherAppsList from "./PublisherAppsList";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import PublisherAppCreate from "./PublisherAppCreate";
import PublisherAppsAnalytics from "./PublisherAppsAnalytics";
import Placements from "../placements/Placements";
import Callout from "@tempoplatform/tpds/components/callout";

import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { toGetApps } from "../api/api";

interface Props {
  accountId: number;
}

const PublisherApps: React.FC<Props> = ({ accountId }) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [error, setError] = useState<any>(null);

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    // ensure we only call getApps if we are at the appropriate path
    if (location.pathname === "/") {
      history.push("/pub/applications");
    } else {
      callGetApps();
    }
  }, [accountId]);

  const callGetApps = async () => {
    const claims: IdToken | undefined = await getIdTokenClaims();
    const id_token: string | undefined = claims?.__raw;
    if (!id_token) {
      setError("Failed to get valid Auth0 token.");
      setLoading(false);
      return;
    }
    try {
      const response = await toGetApps(accountId, id_token);
      setData(response);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error);
      throw new Error(error);
    }
  };

  // render null if we aren't at the component's specific route
  if (location.pathname === "/") return null;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Callout variant="danger" title={"Error"} text={error?.response?.data?.detail} />;
  }
  if (!data) {
    return <Callout variant="danger" title={"Error"} text={"Error loading apps"} />;
  }

  return (
    <Switch>
      <Route exact path="/pub/applications/:appId/placements" render={() => <Placements />} />
      <Route
        exact
        path="/pub/applications/create"
        render={() => <PublisherAppCreate accountId={accountId} />}
      />
      <Route
        exact
        path="/pub/applications"
        render={() => <PublisherAppsList data={data} accountId={accountId} />}
      />
      <Route
        exact
        path="/pub/reports"
        render={() => <PublisherAppsAnalytics accountId={accountId} />}
      />
      <Route
        exact
        path="/pub"
        render={() => <PublisherAppsList data={data} accountId={accountId} />}
      />
      <Route exact path="" render={() => <PublisherAppsList data={data} accountId={accountId} />} />
    </Switch>
  );
};
export default PublisherApps;
