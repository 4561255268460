import React from "react";
import { P, PSmall } from "@tempoplatform/tpds/elements/typography";
import { Window } from "@tempoplatform/tpds/elements/layout";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import ColorPicker from "@tempoplatform/tpds/components/color-picker";
import Select from "@tempoplatform/tpds/components/select";
import { PlacementType_Enum } from "../types/enums";
import { Placement } from "../types/types";
import clsx from "clsx";
import PlacementUVCoordinate from "./PlacementUVCoordinate";
import PlacementWidthHeight from "./PlacementWidthHeight";
import { useAppDispatch } from "../../../app/hooks";
import { addUVCoordinate, removePlacement, updatePlacement } from "../reducer";
import { Cross } from "@tempoplatform/tpds/assets/svgs/tiny";

const typeSelectOptions = [
  { value: PlacementType_Enum.QUAD, label: "QUAD" },
  { value: PlacementType_Enum.MAPPED, label: "MAPPED" },
];

export type Props = {
  placement: Placement;
};

const PlacementsListItem: React.FC<Props> = props => {
  const { placement } = props;

  const dispatch = useAppDispatch();

  //don't render if placement is null
  if (!placement) return null;

  console.log("placement.texture_map_size", placement.texture_map_size);

  // get index in array typeSelectOptions that placement.type is equal to
  const typeIndex = typeSelectOptions.findIndex(option => option.value === placement!.type);

  const updateCustomName = (value: string) => {
    dispatch(updatePlacement({ ...placement, custom_name: value }));
  };
  const updateTextureMapSize = (value: number) => {
    console.log("updateTextureMapSize :: value", value);

    dispatch(updatePlacement({ ...placement, texture_map_size: value }));
  };
  const updatePlacementType = (index: number) => {
    dispatch(updatePlacement({ ...placement, type: typeSelectOptions[index].value }));
  };
  const updatePlacementBackgroundColor = (value: string) => {
    dispatch(updatePlacement({ ...placement, background_color: value }));
  };

  const addNewCoordinate = () => {
    dispatch(
      addUVCoordinate({
        ...placement,
      }),
    );
  };

  const invokeRemovePlacement = (placement: Placement) => {
    console.log("invokeRemovePlacement ::: placement.id", placement.id);
    dispatch(removePlacement(placement));
  };

  const newCoordinateButtonStyle = clsx(
    "transition shadow h-full w-full flex items-center justify-center",
    "border-2 border-transparent",
    "cursor-pointer rounded-lg",
    "hover:border-grey-light-scale-600 dark:hover:border-grey-light-scale-900",
  );

  console.log("PlacementsListItem - render");
  return (
    <Window className="relative mt-6 p-0 border-none shadow !rounded-lg">
      <Cross
        className="text-secondary cursor-pointer hover:text-primary absolute top-2 right-2"
        onClick={() => invokeRemovePlacement(placement!)}
      />
      <div className="grid grid-cols-9">
        <div className="col-span-3 flex flex-col gap-4 w-full items-start p-6 dark:bg-grey-dark-scale-700">
          <P isBold className="leading-none mb-4">
            Placement Profile
          </P>
          <div className="w-full">
            <PSmall isMedium className="text-secondary mb-2">
              Profile Name
            </PSmall>
            <TextInput
              value={placement!.custom_name}
              placeholder="Profile name"
              onChange={(e: any) => updateCustomName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <PSmall isMedium className="text-secondary mb-2">
              Placement Type
            </PSmall>
            <Select
              handleIndexSelection={(index: number) => updatePlacementType(index)}
              selectedIndex={typeIndex}
              labelProp="label"
              idProp="value"
              options={typeSelectOptions}
            />
          </div>
          {/*<div className="w-full">
            <PSmall isMedium className="text-secondary mb-2">
              Texture Map Size
            </PSmall>
            <NumberInput
              initialValue={placement!.texture_map_size}
              outlineStyle={true}
              floatPrecision={4}
              allowFloats={true}
              onChangeCallback={(value: number) => updateTextureMapSize(value)}
            />
          </div>*/}
          {placement.type == "MAPPED" && (
            <div className="w-full">
              <PSmall isMedium className="text-secondary mb-2">
                Optional Fill Color
              </PSmall>
              <ColorPicker
                hexColor={placement!.background_color}
                onChangeHandler={(value: string) => updatePlacementBackgroundColor(value)}
              />
            </div>
          )}
        </div>
        {placement.type == "MAPPED" && (
          <div className="col-span-6 flex flex-col gap-4 w-full items-start border-l border-grey-light-scale-300 dark:border-grey-dark-scale-400 p-6 bg-white dark:bg-grey-dark-scale-700 rounded-tr-lg rounded-br-lg">
            <P isBold className="leading-none mb-4">
              Versions
            </P>
            <div className="grid grid-cols-1 gap-4 items-start w-full h-full">
              {placement.placement_coordinates &&
                placement.placement_coordinates.map((coordinate, index) => (
                  <PlacementUVCoordinate
                    key={index}
                    placement_id={placement.id}
                    coordinate={coordinate}
                    index={index}
                  />
                ))}
              {placement.placement_coordinates && placement.placement_coordinates.length < 5 && (
                <div className={newCoordinateButtonStyle} onClick={() => addNewCoordinate()}>
                  <div className="rounded-full border-grey-light-scale-400 dark:border-grey-dark-scale-200 border-2 w-[60px] h-[60px] flex items-center justify-center">
                    <IconPlus />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {placement.type == "QUAD" && (
          <div className="col-span-6 flex flex-col gap-4 w-full items-start border-l border-grey-light-scale-300 dark:border-grey-dark-scale-400 p-6 bg-white dark:bg-grey-dark-scale-700 rounded-tr-lg rounded-br-lg">
            <div className="grid grid-cols-1 gap-4 items-start w-full h-full">
              <PlacementWidthHeight placement={placement} />
            </div>
          </div>
        )}
      </div>
    </Window>
  );
};

export default PlacementsListItem;

function IconPlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="fill-current text-grey-light-scale-600"
      viewBox="0 0 24 24"
    >
      <path d="M5 12h14M12 5v14"></path>
    </svg>
  );
}
