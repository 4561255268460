import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { Placement, UVCoordinate } from "./types/types";
// import { PlacementType_Enum } from "./types/enums";
// import { PlacementsCampaign, PlacementCoordinateUpdate } from "./types/interfaces";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

export const initialState: any = {};

const placementsCampaignCreateSlice = createSlice({
  name: "placementsCampaignCreate",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
});

export const { resetState } = placementsCampaignCreateSlice.actions;
export default placementsCampaignCreateSlice.reducer;
