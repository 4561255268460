import React, { useState } from "react";
import "../campaign/styles.css";
import ImagesAndUrls from "./ImagesAndUrls";
import Products from "./Products";
import Delivery from "./Delivery";
import { useHistory } from "react-router-dom";
import MainAd from "./MainAd";
import Loading from "../loading/Loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { decrementStep, incrementStep, selectPreviewProduct } from "./campaignCreateSlice";
import PhonePreview from "./PhonePreview";
import ColorModeControl from "../../color/ThemeControl";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { PageRoot } from "@tempoplatform/tpds/elements/layout";
import { P, PLarge, PSmall } from "@tempoplatform/tpds/elements/typography";
import Steps from "@tempoplatform/tpds/components/steps";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { Button as ButtonComponent } from "@tempoplatform/tpds/components/buttons";
import { Cross } from "@tempoplatform/tpds/assets/svgs/tiny";
import validateStep1 from "./step_validators/step1";
import validateStep2 from "./step_validators/step2";
import validateStep3 from "./step_validators/step3";
// @ts-ignore
import ErrorsDisplay from "./ErrorsDisplay";
import Modal from "@tempoplatform/tpds/components/modal";
import prepareCreateCampaignData from "./helpers/prepareCreateCampaignData";
import { GetAccountResponse, getCampaignEnums, toGetAccount, toPostCampaign } from "../api/api";
import { resetCampaignCreate, setEnumsData } from "../campaignCreate/campaignCreateSlice";

const classTopBar = `h-[50px] w-full px-4
  justify-between flex items-center bg-window border-b
  border-grey-light-scale-300 dark:border-grey-dark-scale-600`;

const classCreateArea = `w-full flex bg-body`;
const classSideBar = `bg-window h-full w-[550px] border-r border-window`;
const classNextPrevButtons = `flex justify-between gap-2 mt-4 mb-8`;
const classPreviewArea = `h-screen w-full relative bg-grey-light-scale-400
dark:bg-grey-dark-scale-400`;

const classHeader = `w-full p-4 flex items-center
  bg-window border-b border-grey-light-scale-300 dark:border-grey-dark-scale-600
  h-[60px] flex`;

const classPreviewLayout = `flex items-center justify-center overflow-y-auto`;

const classPreviewChild = `w-[320px] 2xl:w-[380px] aspect-[9/19]
  relative rounded-[20px] overflow-hidden
  bg-grey-light-scale-500 shadow-lg pt-20 pb-20`;

const defaultSteps = [
  {
    title: "Main Ad",
    component: MainAd,
  },
  {
    title: "Add Products",
    component: Products,
  },
  {
    title: "Delivery",
    component: Delivery,
  },
];

const webpageSteps = [
  {
    title: "Main Ad",
    component: MainAd,
  },
  {
    title: "Images and URLs",
    component: ImagesAndUrls,
  },
  {
    title: "Delivery",
    component: Delivery,
  },
];

const CampaignCreate: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getIdTokenClaims } = useAuth0();
  const userId = useAuth0()?.user?.sub as string;
  const cState = useAppSelector(state => state.campaignCreate);
  //console.log("cState", cState);
  const currentStep = useAppSelector(state => state.campaignCreate.currentStep);
  const [accountData, setAccountData] = useState<GetAccountResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [submittedStep1, setSubmittedStep1] = useState<boolean>(false);
  const [submittedStep2, setSubmittedStep2] = useState<boolean>(false);
  const [submittedStep3, setSubmittedStep3] = useState<boolean>(false);
  const [submitErrors, setSubmittErrors] = useState(null);
  const [submitSuccess, setSubmittSuccess] = useState<boolean>(false);

  let dynamicSteps = defaultSteps;
  if (cState.ad_type === "Redirect to Webpage") {
    dynamicSteps = webpageSteps;
  }

  React.useEffect(() => {
    getEnumsAndAccountInformation();
  }, [userId]);

  const getEnumsAndAccountInformation = async () => {
    const claims: IdToken | undefined = await getIdTokenClaims();
    const id_token: string | undefined = claims?.__raw;
    if (!id_token) {
      throw new Error("Failed to get valid Auth0 token.");
    }
    try {
      const response: GetAccountResponse | null = await toGetAccount(userId, id_token);
      if (!response) {
        throw new Error("Account not found");
      }
      const receivedEnumsData = await getCampaignEnums(id_token);
      dispatch(setEnumsData(receivedEnumsData.data));
      setAccountData(response);
      if (response.id) {
        setLoading(false);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const [isPublishing, setIsPublishing] = React.useState(false);

  console.log(`Flow: New campaign - step ${currentStep + 1}`);

  if (loading) {
    return <Loading />;
  }

  if (!accountData || !accountData.id) {
    return <div>ERROR</div>;
  }

  const navigateToNextPage = () => {
    //console.log("navigateToNextPage");
    dispatch(incrementStep());
  };

  const navigateToPreviousPage = () => {
    //console.log("navigateToPreviousPage");
    dispatch(decrementStep());
  };

  const createCampaign = async () => {
    //console.log("createCampaign (send request)");
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;

    let accountId = accountData.id;
    const payloadData = prepareCreateCampaignData(accountId, cState);
    // console.log("payloadData", payloadData);
    setSubmittErrors(null);
    setIsPublishing(true);
    try {
      const response = await toPostCampaign(payloadData, id_token);
      console.info("Campaign submit success:", response);
      setSubmittSuccess(true);
      setIsPublishing(false);
    } catch (error) {
      setIsPublishing(false);
      console.error("Campaign submit error:", error);
      // @ts-ignore
      setSubmittErrors(error?.response?.data?.detail);
    }
  };

  const handleCampaignCreateCancel = () => {
    dispatch(resetCampaignCreate());
    history.push("/campaigns");
  };

  const validateStep = (step: number) => {
    const validationFunction =
      step === 0 ? validateStep1 : step === 1 ? validateStep2 : validateStep3;
    return validationFunction(cState);
  };

  const validateStepAndProceed = (step: number) => {
    if (step === 0) {
      setSubmittedStep1(true);
    }
    if (step === 1) {
      setSubmittedStep2(true);
    }
    if (step === 2) {
      setSubmittedStep3(true);
    }
    const validationResult = validateStep(step);

    if (validationResult.validated) {
      if (step < 2) {
        navigateToNextPage();
      } else {
        createCampaign();
      }
    }
    return validationResult.validated;
  };

  const currentStepValidation = validateStep(currentStep);
  const validationErrors = currentStepValidation.errors;
  const currentStepValidationSuccessful = currentStepValidation.validated;

  const errorModal = (
    // @ts-ignore
    <Modal
      // @ts-ignore
      showOpen={submitErrors !== null}
      title="The request failed"
      content={
        <PSmall className="leading-normal">
          An error while submitting the form. Please review your data or contact support.
        </PSmall>
      }
      options={[
        {
          label: "Return to form",
          variant: "info",
          callBack: () => {
            setSubmittErrors(null);
          },
        },
      ]}
    />
  );

  const successModal = (
    // @ts-ignore
    <Modal
      showOpen={true}
      title="Campaign created!"
      content={<P>Awesome! Your ad is now publishing. 🥳</P>}
      dialogOnClose={() => {
        window.location.href = "/campaigns";
        //console.log('done')
      }}
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            window.location.href = "/campaigns";
            //console.log('done')
          },
        },
      ]}
    />
  );

  const handleNavigateToPreviousPage = (step: number) => {
    navigateToPreviousPage();
    if (cState.ad_type == "Redirect to Webpage") {
      return;
    }
    switch (step) {
      case 2:
        dispatch(selectPreviewProduct(1));
        break;
      default:
        dispatch(selectPreviewProduct(0));
        break;
    }
  };

  const handleValidateStepAndProceed = (step: number) => {
    const validationResult = validateStepAndProceed(step);
    if (cState.ad_type == "Redirect to Webpage") {
      return;
    }
    if (validationResult) {
      switch (step) {
        case 0:
          dispatch(selectPreviewProduct(1));
          break;
        default:
          dispatch(selectPreviewProduct(0));
          break;
      }
    }
  };

  const StepComponent = dynamicSteps[currentStep].component;

  return (
    <PageRoot className="fixed top-0 left-0 z-30">
      <div className={classTopBar}>
        <div className="flex items-center relative top-[-1px]">
          <img className="logo" src={"/logo.png"} alt="Tempo logo" />
          <PLarge isBold className="!ml-2 leading-none">
            TEMPO
          </PLarge>
        </div>
        <PLarge isMedium className="leading-none relative top-[-2px]">
          New campaign
        </PLarge>
        <div className="flex items-center gap-x-4">
          <ColorModeControl />
          <ButtonComponent
            label="Cancel"
            variant="secondary"
            iconAfter={<Cross />}
            onClick={handleCampaignCreateCancel}
          />
        </div>
      </div>
      <div className={classCreateArea} style={{ height: "calc(100vh - 50px)" }}>
        <div className={classSideBar}>
          <div className={classHeader}>
            <Steps steps={dynamicSteps} currentStepIndex={currentStep} />
          </div>
          <div className="overflow-y-scroll" style={{ height: "calc(100vh - 110px)" }}>
            <fieldset>
              <StepComponent />
            </fieldset>
            <div className="mx-4">
              <ErrorsDisplay
                submittedStep1={submittedStep1}
                submittedStep2={submittedStep2}
                submittedStep3={submittedStep3}
                currentStep={currentStep}
                errors={validationErrors}
                currentStepValidationSuccessful={currentStepValidationSuccessful}
              />
              <div className={classNextPrevButtons}>
                {currentStep > 0 && (
                  <Button
                    variant="secondary"
                    onClick={() => handleNavigateToPreviousPage(currentStep)}
                  >
                    Previous
                  </Button>
                )}
                {currentStep < dynamicSteps.length - 1 && (
                  <Button
                    className="w-full"
                    variant="info"
                    onClick={() => handleValidateStepAndProceed(currentStep)}
                  >
                    Next
                  </Button>
                )}
                {currentStep === dynamicSteps.length - 1 && (
                  <ButtonComponent
                    loading={isPublishing}
                    variant="info"
                    className="w-full"
                    label="Publish"
                    onClick={() => validateStepAndProceed(currentStep)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classPreviewArea}>
          <div
            className={
              classHeader +
              "flex justify-center !bg-grey-light-scale-200 dark:!bg-grey-dark-scale-500"
            }
          >
            <P className="text-secondary">Preview</P>
          </div>
          <div className={classPreviewLayout} style={{ height: "calc(100vh - 110px)" }}>
            <div className={classPreviewChild}>
              <PhonePreview />
            </div>
          </div>
        </div>
      </div>
      {errorModal}
      {submitSuccess && successModal}
    </PageRoot>
  );
};
export default CampaignCreate;
