import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import Select from "@tempoplatform/tpds/components/select";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import { FormLabelTiny, PSmall, PLarge } from "@tempoplatform/tpds/elements/typography";
import { FieldType } from "../../../../constants/enums";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { CustomField } from "../../../../constants/interfaces";
import { log } from "util";

const DynamicFormPreview: React.FC = () => {
  let formFields = useAppSelector(state => state.campaignCreate.formFields);
  const products = useAppSelector(state => state.campaignCreate.products);

  const prepareSelectFormFieldValues = (label: string, array: string[]) => {
    const valueOptions = array.map((propertyValue, index) => {
      return {
        value: index.toString(),
        label: propertyValue,
      };
    });

    valueOptions.unshift({
      value: "-1",
      label: label || "Select one",
    });

    return valueOptions;
  };

  const showModelSelection = formFields.model;
  const modelLabel = formFields.model_label;

  const productOptions = products.map((product: any, index: number) => {
    return {
      value: index,
      label: product.name || `No title (${index})`,
    };
  });

  productOptions.unshift({
    value: -1,
    label: modelLabel || "Select one",
  });

  const productSelector = showModelSelection ? (
    <div>
      <Select
        forceLightMode={true}
        handleIndexSelection={(index: number) => {
          // updateFieldValue("model", index);
        }}
        className="light"
        selectedIndex={0}
        labelProp="label"
        idProp="value"
        options={productOptions}
        isInvalid={false} // select controls always display a valid option
      />
    </div>
  ) : null;
  return (
    <div className="w-[90%] mx-auto my-4 light flex flex-col gap-2">
      <PLarge className="text-center w-full mx-auto mb-2.5 max-w-[96%]">
        {formFields.formHeadline}
      </PLarge>
      {productSelector}
      {formFields.custom_fields.map((field: CustomField, index: number) => {
        if (field.type === FieldType.SELECT) {
          return (
            <div key={index}>
              <Select
                forceLightMode={true}
                handleIndexSelection={(index: number) => {
                  // updateFieldValue(field.property_name, index);
                }}
                className="light"
                selectedIndex={0}
                labelProp="label"
                idProp="value"
                options={prepareSelectFormFieldValues(field.label, field.values)}
                isInvalid={false} // select controls always display a valid option
              />
            </div>
          );
        }
        return (
          <div key={index}>
            <TextInput
              className="!bg-transparent !border-[#ededed] active:!border-blue focus:!border-blue border-2 placeholder-[#A1B0B9]"
              forceLightMode={true}
              value=""
              isInvalid={false}
              placeholder={field.label}
              onChange={(e: any) => {}}
            />
          </div>
        );
      })}
      <Button variant="info" forceLightMode>
        Submit
      </Button>
    </div>
  );
};
export default DynamicFormPreview;
