import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_CNAME
    ? process.env.REACT_APP_AUTH0_CNAME
    : process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const history = useHistory();

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  // For debugging
  //console.log(`AUTH0_CLIENT_ID: ${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
  //console.log(`AUTH0_DOMAIN: ${process.env.REACT_APP_AUTH0_DOMAIN}`);
  //console.log(`AUTH0_CNAME: ${process.env.REACT_APP_AUTH0_CNAME}`);
  //console.log(`Domain: ${process.env.REACT_APP_AUTH0_CNAME}`);
  //console.log(`Redirecting: ${window.location.origin}`);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
