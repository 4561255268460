export enum AdTypes {
  shoppable = "Shoppable",
  brand_awareness = "Brand Awareness",
  images_form = "Media & Form",
  redirect_to_webpage = "Redirect to Webpage",
}

export enum FieldType {
  SELECT = "Select",
  TEXTFIELD = "Text field",
}

export enum Country_Enum {
  /** Canada */
  Canada = "CANADA",
  /** United States */
  UnitedStates = "UNITED_STATES",
}

export enum Currency_Enum {
  /** CAD */
  Cad = "CAD",
  /** EUR */
  Eur = "EUR",
  /** USD */
  Usd = "USD",
}

// export enum Location_Enum {
//   /** United States */
//   Us = "US",
//   /** North America */
//   Gb = "GB",
// }

// export enum Gender_Enum {
//   /** All */
//   All = "ALL",
//   /** Female */
//   Female = "FEMALE",
//   /** Male */
//   Male = "MALE",
// }

// data prepared for selects
// data prepared for selects
// data prepared for selects

// export const locationsData = [
//   { value: Location_Enum.Us, label: "North America" },
//   { value: Location_Enum.Gb, label: "Great Britain" },
// ];

export const enums = {
  delivery_status: [
    { id: "ERRORED", value: "Errored" },
    { id: "LIVE", value: "Live" },
    { id: "SCHEDULED", value: "Scheduled" },
  ],
  // gender: [
  //   { id: "MALE", value: "Male" },
  //   { id: "FEMALE", value: "Female" },
  //   { id: "ALL", value: "All" },
  // ],
  location: [
    { id: "US", value: "United States of America" },
    { id: "GB", value: "United Kingdom of Great Britain and Northern Ireland" },
  ],
};
