import React, { useState } from "react";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Window } from "@tempoplatform/tpds/elements/layout";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { toPostPlacementCampaignAsset } from "../../api/api";
import { PSmall, PTiny } from "@tempoplatform/tpds/elements/typography";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "antd";
import { getFileExtension } from "../../helpers/string";
import AWS from "aws-sdk";

// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import {
  REACT_APP_AWS_ACCESS_ID,
  REACT_APP_AWS_ACCESS_KEY,
  REACT_APP_AWS_CLOUDFRONT_URL,
  REACT_APP_AWS_S3_BUCKET_NAME,
  REACT_APP_AWS_S3_REGION,
} from "../../helpers/getS3Config";
//AWS config
AWS.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
  region: REACT_APP_AWS_S3_REGION,
});

interface Props {
  campaignId: number | undefined;
  placement: any;
  size: any;
  value: string;
}

export const PlacementImageUploader: React.FC<Props> = ({ campaignId, placement, size, value }) => {
  const { getIdTokenClaims } = useAuth0();
  const { Dragger } = Upload;
  const [image, setImage] = useState<string>(value);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const handleImageUpload = async (file: any) => {
    if (!file.type.includes("image")) {
      alert("Please upload an image file");
      return;
    }
    setIsImageUploading(true);
    const fileExtension = getFileExtension(file.name);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    const newFileURL = `https://${REACT_APP_AWS_CLOUDFRONT_URL}/images/${newFileName}`;

    const params = {
      Bucket: REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `images/${newFileName}`,
      Body: file,
    };
    var upload = s3
      // @ts-ignore
      .putObject(params)
      .promise();

    await upload.then((data: any) => {
      setIsImageUploading(false);
      if (data.$response.httpResponse.statusMessage === "OK") {
        console.log("Upload successful. New file URL:", newFileURL);
        setImage(newFileURL);
        storeUploadedImage(newFileURL);
      } else {
        alert("Upload failed");
      }
    });
  };
  const storeUploadedImage = async (newFileURL: string) => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    let data = [
      {
        placement_id: placement.id,
        url: newFileURL,
      },
    ];
    if (campaignId) toPostPlacementCampaignAsset(campaignId, data, id_token);
  };
  const handleImageRemove = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    setImage("");
    let data = [
      {
        placement_id: placement.id,
        url: "",
      },
    ];
    if (campaignId) toPostPlacementCampaignAsset(campaignId, data, id_token);
  };

  if (!campaignId) {
    return (
      <div className="bg-window border border-window !border-0 dark:!border-2 shadow-sm dark:shadow-none rounded-sm lg:rounded-[8px] py-xs px-xs">
        <PTiny className="!mb-1 !mt-1">
          <b>{placement.custom_name}</b> ({placement.type})<br />
        </PTiny>
        <PTiny className="!mb-1 !mt-1">
          Width: {size.width}, Height: {size.height}
        </PTiny>
      </div>
    );
  }

  return (
    <div className="bg-window border border-window !border-0 dark:!border-2 shadow-sm dark:shadow-none rounded-sm lg:rounded-[8px] py-xs px-xs">
      <PTiny className="!mb-1 !mt-1">
        <b>{placement.custom_name}</b> ({placement.type})<br />
      </PTiny>
      <PTiny className="!mb-4 !mt-1">
        Width: {size.width}, Height: {size.height}
      </PTiny>
      {!image && (
        <Dragger
          beforeUpload={handleImageUpload}
          multiple={false}
          fileList={[]}
          className="!bg-window !border-window"
        >
          {isImageUploading && <LoadingOutlined />}
          <span
            style={{
              display: "block",
              fontSize: "30px",
              lineHeight: "100%",
              marginBottom: "10px",
              opacity: "0.3",
            }}
          >
            <CloudUploadOutlined size={0} className="!text-primary" />
          </span>
          <PSmall isMedium className="leading-[130%] !text-secondary">
            Drop your image here, or <br />
            <span style={{ color: "#1990ff" }}>click to upload</span>
          </PSmall>
        </Dragger>
      )}
      {image !== "" ? (
        <Window className="flex-row flex gap-x-4 !p-4 items-center justify-between">
          <img
            alt=""
            src={image}
            style={{
              width: "auto",
              maxHeight: "100px",
            }}
          />
          <Button className="w-[50%]" onClick={handleImageRemove}>
            Remove image
          </Button>
        </Window>
      ) : null}
    </div>
  );
};
