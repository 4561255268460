import React, { useState } from "react";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import Checkbox from "@tempoplatform/tpds/components/checkbox";
import { FormLabelTiny, PSmall, PLarge } from "@tempoplatform/tpds/elements/typography";
import LabelWithTooltip from "../../../shared/LabelWithTooltip";
import { Separator } from "@tempoplatform/tpds/elements/layout";
import Select from "@tempoplatform/tpds/components/select";
import Tag from "@tempoplatform/tpds/components/tag";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateFormData } from "../campaignCreateSlice";
import { FormFields, CustomField } from "../../../constants/interfaces";
import { FieldType } from "../../../constants/enums";
import clsx from "clsx";

const activeStyle = "cursor-pointer opacity-50 hover:opacity-100";
const inactiveStyle = "pointer-events-none opacity-10";

const ImagesFormAdTypeConfigurator = () => {
  const dispatch = useAppDispatch();
  const formFields = useAppSelector(state => state.campaignCreate.formFields);
  const [newCustomFieldInterfaceActive, setNewCustomFieldInterfaceActive] =
    useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>("");
  const [newFieldMandatory, setNewFieldMandatory] = useState<boolean>(false);
  const [newValuesArray, setNewValuesArray] = useState<string[]>([]);
  const [newCustomFieldTypeselectionIndex, setNewCustomFieldTypeselectionIndex] =
    useState<number>(0);
  const [newFieldLabel, setNewFieldLabel] = useState<string>("");

  const handleSaveNewFormField = () => {
    const updatedFormFields = { ...formFields };
    const newFormField: CustomField = {
      label: newFieldLabel,
      type: newCustomFieldTypeselectionIndex === 0 ? FieldType.TEXTFIELD : FieldType.SELECT,
      values: newCustomFieldTypeselectionIndex === 0 ? [] : newValuesArray,
      mandatory: newFieldMandatory,
    };
    const updatedFields = [...updatedFormFields.custom_fields, newFormField];
    updatedFormFields.custom_fields = updatedFields;

    dispatch(updateFormData(updatedFormFields));
    resetFields();
  };

  const moveUpFieldAtIndex = (index: number) => {
    const updatedFormFields = { ...formFields };
    const updatedFields = [...updatedFormFields.custom_fields];
    const temp = updatedFields[index];
    updatedFields[index] = updatedFields[index - 1];
    updatedFields[index - 1] = temp;
    updatedFormFields.custom_fields = updatedFields;
    dispatch(updateFormData(updatedFormFields));
  };

  const moveDownFieldAtIndex = (index: number) => {
    const updatedFormFields = { ...formFields };
    const updatedFields = [...updatedFormFields.custom_fields];
    const temp = updatedFields[index];
    updatedFields[index] = updatedFields[index + 1];
    updatedFields[index + 1] = temp;
    updatedFormFields.custom_fields = updatedFields;
    dispatch(updateFormData(updatedFormFields));
  };

  const removeFieldAtIndex = (index: number) => {
    const updatedFormFields = { ...formFields };
    const updatedFields = [...updatedFormFields.custom_fields];
    updatedFields.splice(index, 1);
    updatedFormFields.custom_fields = updatedFields;
    dispatch(updateFormData(updatedFormFields));
  };

  const resetFields = () => {
    setNewCustomFieldInterfaceActive(false);
    setNewCustomFieldTypeselectionIndex(0);
    setNewValue("");
    setNewFieldLabel("");
    setNewValuesArray([]);
    setNewFieldMandatory(false);
  };

  let readyToSaveNewField = true;
  const showNeedsAtLeastTwoOptionsWarning =
    newCustomFieldTypeselectionIndex === 1 && newValuesArray.length < 2;
  if (newFieldLabel === "" || showNeedsAtLeastTwoOptionsWarning) {
    readyToSaveNewField = false;
  }

  const needsLabel = newFieldLabel === "";

  const customFields = formFields.custom_fields;

  return (
    <>
      <PLarge className="mt-8">Form fields</PLarge>
      <Separator className="my-4" />
      <div className="mt-0">
        <PSmall className="text-tertiary font-medium mb-2">The headline of the form</PSmall>
        <TextInput
          // className="!bg-transparent !border-[#ededed] active:!border-blue focus:!border-blue border-2"
          value={formFields.formHeadline}
          isInvalid={false}
          placeholder="Form headline"
          onChange={(e: any) => {
            const updatedFormData: FormFields = { ...formFields };
            updatedFormData.formHeadline = e.target.value;
            dispatch(updateFormData(updatedFormData));
          }}
        />
      </div>
      <div className="flex items-center mt-4 mb-1 gap-2 mb-2">
        <Checkbox
          active={formFields.model}
          onClick={() => {
            const updatedFormFields = { ...formFields };
            updatedFormFields.model = !updatedFormFields.model;
            dispatch(updateFormData(updatedFormFields));
          }}
        />
        <PSmall className="text-tertiary font-medium">Enable product selection</PSmall>
      </div>
      {formFields.model && (
        <TextInput
          // className="!bg-transparent !border-[#ededed] active:!border-blue focus:!border-blue border-2"
          value={formFields.model_label}
          isInvalid={false}
          placeholder="Which label to use (eg. Model/Product)"
          onChange={(e: any) => {
            const updatedFormData: FormFields = { ...formFields };
            updatedFormData.model_label = e.target.value;
            dispatch(updateFormData(updatedFormData));
          }}
        />
      )}
      {customFields && customFields.length > 0 && (
        <div className="flex flex-col gap-1 mt-4">
          {customFields.map((customField, index) => {
            const isFirst = index === 0;
            const isLast = index === customFields.length - 1;
            return (
              <div
                key={index}
                className="bg-white border-2 border-grey-light-scale-300 dark:border-transparent dark:bg-grey-dark-scale-700 rounded w-full p-2"
              >
                <div className="flex justify-between w-full items-start">
                  <FormLabelTiny
                    className={clsx(
                      "!m-0 !text-[12px] leading-none uppercase select-none",
                      customField.type === FieldType.TEXTFIELD
                        ? "!text-green-scale-500"
                        : "!text-orange-scale-500",
                    )}
                  >
                    {customField.type === FieldType.TEXTFIELD ? "Simple input" : "Multiple options"}
                  </FormLabelTiny>
                  <div className="flex items-start gap-2 select-none">
                    {customField.mandatory && (
                      <FormLabelTiny
                        className={clsx(
                          "m-0 leading-none uppercase select-none text-red-scale-500",
                        )}
                      >
                        REQUIRED
                      </FormLabelTiny>
                    )}
                    <div className="flex items-center gap-1 select-none p-1 rounded relative top-[-3px] right-[-3px]">
                      <ArrowUpIcon
                        className={!isFirst ? activeStyle : inactiveStyle}
                        onClick={!isFirst ? () => moveUpFieldAtIndex(index) : null}
                      />
                      <ArrowDownIcon
                        className={!isLast ? activeStyle : inactiveStyle}
                        onClick={!isLast ? () => moveDownFieldAtIndex(index) : null}
                      />
                      <RemoveIcon
                        className={activeStyle}
                        onClick={() => removeFieldAtIndex(index)}
                      />
                    </div>
                  </div>
                </div>
                <PSmall className="text-primary mt-1 leading-none m-0 select-none">
                  {customField.label}
                </PSmall>
                {customField.type === FieldType.SELECT && (
                  <div className="flex gap-1 mt-2.5">
                    {customField.values.map((value, index) => {
                      return <Tag key={index} label={value} variant="default" />;
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {newCustomFieldInterfaceActive && (
        <div className="flex flex-col gap-x-4 w-full p-4 pt-3.5 rounded-lg bg-white dark:bg-grey-dark-scale-900 mt-4 active-border-animation">
          <div className="w-full flex justify-between items-center mb-4">
            <FormLabelTiny className="m-0">NEW FORM FIELD</FormLabelTiny>
            <RemoveIcon className={activeStyle} onClick={() => resetFields()} />
          </div>
          <div className="flex w-full gap-2 items-start justify-between">
            <div>
              <PSmall className="mb-2">Type</PSmall>
              <Select
                handleIndexSelection={(index: number) => {
                  setNewCustomFieldTypeselectionIndex(index);
                }}
                selectedIndex={newCustomFieldTypeselectionIndex}
                labelProp="label"
                idProp="value"
                options={[
                  { value: "1", label: "Text input" },
                  { value: "0", label: "Multiple options" },
                ]}
                isInvalid={false}
              />
            </div>
            <div>
              <PSmall className="mb-2">Label</PSmall>
              <TextInput
                value={newFieldLabel}
                isInvalid={false}
                placeholder="Field label"
                onChange={(e: any) => {
                  setNewFieldLabel(e.target.value);
                }}
              />
            </div>
          </div>
          {newCustomFieldTypeselectionIndex === 1 && (
            <>
              <PSmall className="mt-4">Values</PSmall>
              <div className="relative mt-2">
                <TextInput
                  value={newValue}
                  className="w-full"
                  isInvalid={false}
                  placeholder="Type a possible value"
                  onChange={(e: any) => setNewValue(e.target.value)}
                />
                <Button
                  variant="info"
                  className="absolute right-2 top-2 h-6"
                  onClick={() => {
                    if (newValue !== "") {
                      const updatedNewValuesArray = [...newValuesArray];
                      updatedNewValuesArray.push(newValue);
                      setNewValuesArray(updatedNewValuesArray);
                      setNewValue("");
                    }
                  }}
                >
                  ADD
                </Button>
              </div>
              <div className="w-full flex flex-wrap mt-2">
                {newValuesArray.length > 0 &&
                  newValuesArray.map((value, index) => {
                    return (
                      <Tag
                        key={index}
                        label={value}
                        showCloseIcon={true}
                        variant="info"
                        className="cursor-pointer"
                        onClick={() => {
                          const updatedNewValuesArray = [...newValuesArray];
                          updatedNewValuesArray.splice(index, 1);
                          setNewValuesArray(updatedNewValuesArray);
                        }}
                      />
                    );
                  })}
              </div>
            </>
          )}
          <div className="w-full flex gap-2 items-center mt-4">
            <Checkbox
              active={newFieldMandatory}
              onClick={() => setNewFieldMandatory(!newFieldMandatory)}
            />
            <PSmall>Required</PSmall>
          </div>
          {readyToSaveNewField && (
            <Button className="w-full mt-4" onClick={handleSaveNewFormField}>
              Save new form field
            </Button>
          )}
          {(showNeedsAtLeastTwoOptionsWarning || needsLabel) && (
            <div className="mt-6 border-t border-grey-light-scale-300 dark:border-grey-dark-scale-600 pt-4">
              {showNeedsAtLeastTwoOptionsWarning && (
                <PSmall className="text-center w-full mb-2 pointer-events-none opacity-60 select-none">
                  Multi-select needs at least two options
                </PSmall>
              )}
              {needsLabel && (
                <PSmall className="text-center w-full pointer-events-none opacity-60 select-none">
                  Label is missing
                </PSmall>
              )}
            </div>
          )}
        </div>
      )}
      {!newCustomFieldInterfaceActive && (
        <Button
          className="w-full mt-4"
          onClick={() => {
            setNewCustomFieldInterfaceActive(true);
            setNewCustomFieldTypeselectionIndex(0);
            setNewValue("");
            setNewFieldLabel("");
            setNewValuesArray([]);
          }}
        >
          Add form field
        </Button>
      )}
    </>
  );
};

export default ImagesFormAdTypeConfigurator;

function ArrowUpIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path fill="#222426" fillOpacity="0.01" d="M0 0H14V12H0z"></path>
      <path
        className="stroke-[#000] dark:stroke-[#B0B9C3]"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 9l5-5 5 5"
      />
    </svg>
  );
}

function ArrowDownIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path fill="#222426" fillOpacity="0.01" d="M0 0H14V12H0z"></path>
      <path
        className="stroke-[#000] dark:stroke-[#B0B9C3]"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 4l5 5 5-5"
      />
    </svg>
  );
}

function RemoveIcon({ ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path fill="#222426" fillOpacity="0.01" d="M0 0H14V12H0z"></path>
      <path
        className="stroke-[#000] dark:stroke-[#B0B9C3]"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 2l-8 8M3 2l8 8"
      />
    </svg>
  );
}
