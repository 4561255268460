/*
  Future improvements for this page:
  - Prevent subtle layout shift after table loading
  - (Maybe) Add pagination to table
*/

import React, { useEffect, useState } from "react";
import { P } from "@tempoplatform/tpds/elements/typography";
import Table from "@tempoplatform/tpds/components/table";
import { Button } from "@tempoplatform/tpds/components/buttons";
import { Window } from "@tempoplatform/tpds/elements/layout";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { toGetAccount, toPatchRevokeApiKey, toPostRequestApiKey } from "../api/api";
import { prepareKeysData, sortKeys } from "./helpers";
import { getTableColumns } from "./tableColumns";
import PageTitle from "./PageTitle";
import StaticContent from "./StaticContent";
import { DeleteApiKeyModal, DeletedApiKeySuccessModal, ErrorModal, SuccessModal } from "./Modals";

const ApiKeys: React.FC = () => {
  const [accountData, setAccountData] = useState<any>(null);
  const [initialised, setInitialised] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [error, setError] = useState<any>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [deletingKey, setDeletingKey] = useState<any>(null);
  const [deletedApiKeySuccess, setDeletedApiKeySuccess] = useState<any>(false);
  const [apiKeyData, setApiKeyData] = useState<any>(null);
  const [submitSuccess, setSubmitSuccess] = useState<any>(false);
  const [keyRequestInProgress, setKeyRequestInProgress] = useState<any>(false);

  const { getIdTokenClaims } = useAuth0();
  const userId = useAuth0()?.user?.sub as string;

  useEffect(() => {
    if (userId) {
      getAccountInformation();
    }
  }, [userId]);

  const getAccountInformation = async () => {
    const claims: IdToken | undefined = await getIdTokenClaims();
    const id_token: string | undefined = claims?.__raw;
    if (!id_token) {
      setError("Failed to get valid Auth0 token.");
      setLoading(false);
      return;
    }
    setAccessToken(id_token);
    try {
      const response = await toGetAccount(userId, id_token);
      setAccountData(response);
      setInitialised(true);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setInitialised(true);
      setLoading(false);
    }
  };

  const handleRevokeApiKey = async () => {
    setLoading(true);
    try {
      const response = await toPatchRevokeApiKey(accountData.id, deletingKey, accessToken);
      setDeletedApiKeySuccess(true);
      setDeletingKey(null);
      setLoading(false);
    } catch (error: any) {
      setDeletingKey(null);
      setError(error);
      setLoading(false);
    }
  };

  const handleCreateNewApiKey = async () => {
    setLoading(true);
    setKeyRequestInProgress(true);
    try {
      const response = await toPostRequestApiKey(accountData.id, accessToken);
      setApiKeyData(response);
      setSubmitSuccess(true);
      setKeyRequestInProgress(false);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setSubmitSuccess(false);
      setKeyRequestInProgress(false);
      setLoading(false);
    }
  };

  const tableColumns = getTableColumns(setDeletingKey);

  const hasKeys = accountData?.api_keys?.length > 0;
  let sortedKeysData = !hasKeys ? null : sortKeys(accountData.api_keys);
  const preparedApiKeysData = !hasKeys ? null : prepareKeysData(sortedKeysData);

  const keysContent = !hasKeys ? (
    <Window className="h-40 mt-12 flex flex-col text-center items-center justify-center">
      <P>
        You currently have no API keys.{" "}
        <span className="font-bold text-blue">Create a new API Key</span> to get started.
      </P>
    </Window>
  ) : (
    <Table
      columns={tableColumns}
      data={preparedApiKeysData}
      pagination={false}
      customHeaderClass="opacity-50"
    />
  );

  return (
    <div>
      <div className="flex justify-between items-end">
        <PageTitle isLoading={loading} />
        {initialised && (
          <Button
            variant="info"
            onClick={handleCreateNewApiKey}
            label="Create new API Key"
            loading={keyRequestInProgress}
          />
        )}
      </div>
      <div className="my-10">{initialised && keysContent}</div>
      <StaticContent />
      {initialised && error && <ErrorModal error={error} setError={setError} />}
      {initialised && apiKeyData && (
        <SuccessModal
          submitSuccess={submitSuccess}
          apiKey={apiKeyData.key}
          apiKeyValidTo={apiKeyData.valid_to}
          setApiKeyData={setApiKeyData}
          setSubmitSuccess={setSubmitSuccess}
          getAccountInformation={getAccountInformation}
        />
      )}
      {initialised && deletingKey && (
        <DeleteApiKeyModal
          deletingKey={deletingKey}
          setDeletingKey={setDeletingKey}
          handleRevokeApiKey={handleRevokeApiKey}
        />
      )}
      {initialised && deletedApiKeySuccess && (
        <DeletedApiKeySuccessModal
          deletedApiKeySuccess={deletedApiKeySuccess}
          setDeletedApiKeySuccess={setDeletedApiKeySuccess}
          getAccountInformation={getAccountInformation}
        />
      )}
    </div>
  );
};

export default ApiKeys;
