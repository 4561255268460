export function uvToNormalisedWH(
  startX = 0,
  startY = 0,
  endX = 1,
  endY = 1,
  orientation = "NORTH",
  uvMultiplier = 1024,
) {
  var rawWidth = endX - startX;
  var rawHeight = endY - startY;

  var height;
  var width;

  // Update target width/height
  if (orientation == "EAST" || orientation == "WEST") {
    height = rawHeight * uvMultiplier; // 1024
    width = rawWidth * uvMultiplier;
  } else {
    height = rawWidth * uvMultiplier;
    width = rawHeight * uvMultiplier;
  }

  return normaliseWH(width, height);
}
export function normaliseWH(width: number, height: number) {
  // If below zero, something funky going on so return 1:1
  if (height < 0 || width < 0) {
    return { width: 1, height: 1 };
  }

  // H/W cannot be less than 256px, so we make smallest size 256px and adjust other length
  if (height < 256 && height < width) {
    var ar = width / height;
    height = 256;
    width = Math.ceil(height * ar);
  } else if (width < 256 && width < height) {
    var ar = height / width;
    width = 256;
    height = Math.ceil(width * ar);
  }

  // Update target width/height
  width = Math.ceil(width);
  height = Math.ceil(height);

  return { width: width, height: height };
}
