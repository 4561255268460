import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import Select from "@tempoplatform/tpds/components/select";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import NumberInput from "@tempoplatform/tpds/components/input/number";
import { UVCoordinate } from "../types/types";
import { useAppDispatch } from "../../../app/hooks";
import { Cross } from "@tempoplatform/tpds/assets/svgs/tiny";
import { removeUVCoordinate, updateUVCoordinate } from "../reducer";
import { uvToNormalisedWH } from "../../../shared/CalculateImageAssetSize";

const orientationTypes = [
  { value: "", label: "" },
  { value: "NORTH", label: "NORTH" },
  { value: "EAST", label: "EAST" },
  { value: "SOUTH", label: "SOUTH" },
  { value: "WEST", label: "WEST" },
];

const coordinatePrefixStyle =
  "!text-secondary border-2 border-[#ededed] !border-r-0 dark:border-[#384147] h-full flex items-center px-2.5 rounded-tl rounded-bl";

interface Props {
  placement_id: string;
  coordinate: UVCoordinate;
  index: number;
}

const PlacementUVCoordinate = (props: Props) => {
  const { placement_id, coordinate, index } = props;
  const dispatch = useAppDispatch();

  var orientationIndexStart = 0;
  for (var i = 0; i < orientationTypes.length; i++) {
    if (orientationTypes[i].value == coordinate.orientation) {
      orientationIndexStart = i;
    }
  }
  const [orientationIndex, setOrientationIndex] = React.useState<number | null>(
    orientationIndexStart,
  );

  console.log("coordinate", coordinate);

  const invokeRemoveUVCoordinate = (coordinate: UVCoordinate) => {
    console.log("CLICK coordinate.id", coordinate.id);

    dispatch(removeUVCoordinate({ placement_id, coordinate }));
  };

  const updateUvStartX = (value: string) => {
    console.log("updateUvStartX", value);
    const updatedCoordinate = { ...coordinate, start_x: parseFloat(value) };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };

  const updateUvStartY = (value: string) => {
    console.log("updateUvStartY", value);
    const updatedCoordinate = { ...coordinate, start_y: parseFloat(value) };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };
  const updateUvEndX = (value: string) => {
    console.log("updateUvEndX", value);
    const updatedCoordinate = { ...coordinate, end_x: parseFloat(value) };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };
  const updateUvEndY = (value: string) => {
    console.log("updateUvEndY", value);
    const updatedCoordinate = { ...coordinate, end_y: parseFloat(value) };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };
  const updateOrientation = (value: string) => {
    const updatedCoordinate = { ...coordinate, orientation: value };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };
  const updateSemanticVersion = (value: string) => {
    const updatedCoordinate = { ...coordinate, semantic_version: value };
    dispatch(
      updateUVCoordinate({
        placement_id,
        coordinate: updatedCoordinate,
      }),
    );
  };

  const size: any = uvToNormalisedWH(
    coordinate.start_x,
    coordinate.start_y,
    coordinate.end_x,
    coordinate.end_y,
    coordinate.orientation,
    1024,
  );

  return (
    <div className="flex flex-col gap-3 h-full shadow bg-transparent p-6 rounded-lg relative border border-transparent dark:border-[rgba(255,255,255,0.1)]">
      <Cross
        className="text-secondary cursor-pointer hover:text-primary absolute top-2.5 right-2.5 w-[14px]"
        onClick={() => invokeRemoveUVCoordinate(coordinate)}
      />
      <div className="grid grid-cols-2 gap-4 items-start w-full h-full">
        <div className="flex flex-col gap-4 w-full items-start dark:bg-grey-dark-scale-700">
          <div className="w-full gap-x-1">
            <PSmall isMedium className="text-secondary mb-2">
              Semantic Version
            </PSmall>
            <TextInput
              value={coordinate!.semantic_version}
              placeholder="Eg: 0.0.1"
              onChange={(e: any) => updateSemanticVersion(e.target.value)}
            />
          </div>

          <div className="w-full gap-x-1">
            <PSmall isMedium className="text-secondary mb-2">
              Orientation
            </PSmall>
            <Select
              selectedIndex={orientationIndex || undefined}
              placeholder="Select orientation"
              labelProp="label"
              idProp="value"
              options={orientationTypes}
              handleIndexSelection={(index: number) => {
                setOrientationIndex(index);
                let value = orientationTypes[index].value;
                updateOrientation(value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full items-start dark:bg-grey-dark-scale-700">
          <div className="flex gap-x-1 space-between items-end">
            <div className="w-full">
              <PSmall isMedium className="text-secondary mb-2">
                Start
              </PSmall>
              <div className="flex gap-x-1">
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    X
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={coordinate.start_x.toString() || "0"}
                    floatPrecision={4}
                    minValue={0}
                    maxValue={1}
                    allowFloats={true}
                    onChangeCallback={(value: string) => updateUvStartX(value)}
                  />
                </div>
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    Y
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={coordinate.start_y.toString() || "0"}
                    floatPrecision={4}
                    minValue={0}
                    maxValue={1}
                    allowFloats={true}
                    onChangeCallback={(value: string) => updateUvStartY(value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-1 space-between items-end">
            <div className="w-full">
              <PSmall isMedium className="text-secondary mb-2">
                End
              </PSmall>
              <div className="flex gap-x-1">
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    X
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={coordinate.end_x.toString() || "0"}
                    floatPrecision={4}
                    minValue={0}
                    maxValue={1}
                    allowFloats={true}
                    onChangeCallback={(value: string) => updateUvEndX(value)}
                  />
                </div>
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    Y
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={coordinate.end_y.toString() || "0"}
                    floatPrecision={4}
                    minValue={0}
                    maxValue={1}
                    allowFloats={true}
                    onChangeCallback={(value: string) => updateUvEndY(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full gap-x-1 grid content-center ">
        <PSmall isMedium className="text-secondary mb-2" style={{ textAlign: "center" }}>
          Image Asset Size - Width: {size.width}, Height: {size.height}
        </PSmall>
      </div>
    </div>
  );
};

export default PlacementUVCoordinate;

function DeleteIcon({ ...props }) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
    >
      <path d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"></path>
    </svg>
  );
}
