import styled from "@emotion/styled";
import { Button as TPDSButton } from "@tempoplatform/tpds/components/buttons";
import { Separator, Window } from "@tempoplatform/tpds/elements/layout";
import { H6, P, PSmall } from "@tempoplatform/tpds/elements/typography";
import { TextInput } from "@tempoplatform/tpds/elements/input";
import RadioTabs from "@tempoplatform/tpds/components/radiotabs";
import React, { useState } from "react";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Country_Enum, Currency_Enum } from "../../constants/enums";
import { getBusinessTypeEnums, toPostOrganization } from "../api/api";
import Modal from "@tempoplatform/tpds/components/modal";
import { useHistory } from "react-router-dom";
import Loading from "../loading/Loading";
import TPDSSelect from "@tempoplatform/tpds/components/select";
import NumberInput from "@tempoplatform/tpds/components/input/number";

export enum Business_Type_Enum {
  /** Agency */
  Agency = "AGENCY",
  /** Brand */
  Brand = "BRAND",
  /** Publisher */
  Publisher = "PUBLISHER",
  /** Small Business */
  SmallBusiness = "SMALL_BUSINESS",
}

const countryCodes = require("country-codes-list");

const allCountries = countryCodes.all();
const parsedCountries = allCountries.map((country: any) => {
  return {
    id: country.countryCode,
    value: `${country.flag} ${country.countryNameEn} +${country.countryCallingCode}`,
    countryCallingCode: country.countryCallingCode,
  };
});

const myCountryCodesObject = countryCodes.customList(
  "countryCode",
  "{countryNameEn}: +{countryCallingCode}",
);

const countries = [
  { id: Country_Enum.UnitedStates, value: "United States" },
  { id: Country_Enum.Canada, value: "Canada" },
];
const currencies = [
  { id: Currency_Enum.Usd, value: "USD" },
  { id: Currency_Enum.Cad, value: "CAD" },
  { id: Currency_Enum.Eur, value: "EUR" },
];

const required = <span className="text-red font-bold">*</span>;

const RootDiv = styled.div`
    .radio-circle {
        position: relative;
        border-color: blue;
    }

    .radio-circle::before {
        content: "";
        width: 4px;
        height: 4px;
        border-color: blue;
        borderradius: 50%;
        top: 2px;
        left: 2px;
    }
`;

const OnBoarding: React.FC = () => {
  const history = useHistory();
  const [accountType, setAccountType] = React.useState({ id: "unset", value: "unset" });
  const [businessType, setBusinessType] = React.useState({ id: "unset", value: "unset" });
  const [isCreatingAccount, setIsCreatingAccount] = React.useState(false);
  const [businessTypeEnums, setBusinessTypeEnums] = React.useState([]);
  const [accountTypeEnums, setAccountTypeEnums] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string>();
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>(false);
  const [selectedCountryIndex, setSelectedCountryIndex] = React.useState<number>(0);
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = React.useState<number>(0);
  const [selectedCountryCallingCode, setSelectedCountryCallingCode] = React.useState<number>();
  const [businessName, setBusinessName] = React.useState("");
  const [businessNameInvalid, setBusinessNameInvalid] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<number>();
  const [phoneNumberNameInvalid, setPhoneNumberInvalid] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const { getIdTokenClaims } = useAuth0();

  React.useEffect(() => {
    getTokenAndEnums();
  }, []);

  // OAuth provider fields
  let userId: string = useAuth0()?.user?.sub as string;
  let email: string = useAuth0()?.user?.email as string;

  const getTokenAndEnums = async () => {
    try {
      const claims: IdToken | undefined = await getIdTokenClaims();
      const id_token: string | undefined = claims?.__raw;
      if (!id_token) {
        setError("Failed to get valid Auth0 token.");
        setLoading(false);
        return;
      }
      setAccessToken(id_token);
      const enums = await getBusinessTypeEnums(id_token);
      setBusinessTypeEnums(enums.business_type);
      setAccountTypeEnums(enums.account_type);
      const publisher_type = enums.account_type.find((type: any) => type.id === "PUBLISHER");
      setAccountType(publisher_type);
      setLoading(false);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Creates account object and update Tempo account backend
  const createAccount = async () => {
    let finalBusinessType = accountType.id === "PUBLISHER" ? "Brand" : businessType.value;
    let country =
      accountType.id === "PUBLISHER" ? "United States" : countries[selectedCountryIndex].value;
    let currency =
      accountType.id === "PUBLISHER" ? Currency_Enum.Usd : currencies[selectedCurrencyIndex].value;

    const data = {
      account_name: businessName,
      user_id: userId,
      currency: currency,
      // @ts-ignore
      phone_country_code: parseInt(parsedCountries[selectedCountryCallingCode].countryCallingCode),
      phone_number: phoneNumber,
      email: email,
      organization_name: businessName,
      country: country,
      business_type: finalBusinessType,
      account_type: accountType.value,
    };

    setIsCreatingAccount(true);

    if (!accessToken) {
      setError("Failed to get valid Auth0 token.");
      setIsCreatingAccount(false);
      setSubmitSuccess(false);
      return;
    }
    try {
      const response = await toPostOrganization(data, accessToken);
      // console.log("callInsertOrganization ::: response:", response);
      setIsCreatingAccount(false);
      setSubmitSuccess(true);
    } catch (error: any) {
      setIsCreatingAccount(false);
      setSubmitSuccess(false);
      setError(error);
    }
  };

  const successModal = (
    // @ts-ignore
    <Modal
      showOpen={true}
      title="Account created!"
      content={<P>Awesome! Your Account is now created. 🥳</P>}
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            accountType.id === "PUBLISHER"
              ? history.push("/pub/applications")
              : history.push("/campaigns");
          },
        },
      ]}
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <RootDiv>
      <Window className="bg-white max-w-[500px] mx-auto">
        <H6>Let's get to know you.</H6>
        <br />
        <PSmall className="leading-normal">
          We'd like to learn a bit about you, so that we can personalize your experience.
        </PSmall>
        <Separator />
        <br />
        <PSmall className="pb-3">Account Type {required}</PSmall>
        <RadioTabs
          items={accountTypeEnums}
          // @ts-ignore
          selectedIndex={accountTypeEnums.indexOf(accountType)}
          handleIndexSelection={(index: number) => {
            // @ts-ignore
            setAccountType(accountTypeEnums[index]);
          }}
        />
        <br />
        <PSmall className="pb-2">Business Name {required}</PSmall>
        <TextInput
          value={businessName}
          isInvalid={businessNameInvalid}
          placeholder="Legal name of business"
          onChange={(e: any) => setBusinessName(e.target.value)}
        />
        {businessNameInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please verify the business name</PSmall>
        )}
        {/* @ts-ignore */}
        {accountType.id !== "PUBLISHER" && (
          <>
            <br />
            <PSmall className="pb-2">Business Type {required}</PSmall>
            <TPDSSelect
              options={businessTypeEnums}
              labelProp="value"
              placeholder="What kind of business are you ?"
              // @ts-ignore
              selectedIndex={businessTypeEnums.indexOf(businessType)}
              //handleIndexSelection={(index: number) => handleMinAgeSelectChange(index)}
              handleIndexSelection={(index: number) => {
                // @ts-ignore
                setBusinessType(businessTypeEnums[index]);
              }}
            />
            <br />
            <PSmall className="pb-2">Country {required}</PSmall>
            <TPDSSelect
              options={countries}
              labelProp="value"
              // @ts-ignore
              selectedIndex={selectedCountryIndex}
              //handleIndexSelection={(index: number) => handleMinAgeSelectChange(index)}
              handleIndexSelection={(index: number) => {
                // @ts-ignore
                setSelectedCountryIndex(index);
              }}
            />
            <br />
            <PSmall className="pb-2">Currency {required}</PSmall>
            <TPDSSelect
              options={currencies}
              labelProp="value"
              // @ts-ignore
              selectedIndex={selectedCurrencyIndex}
              //handleIndexSelection={(index: number) => handleMinAgeSelectChange(index)}
              handleIndexSelection={(index: number) => {
                // @ts-ignore
                setSelectedCurrencyIndex(index);
              }}
            />
          </>
        )}
        <br />
        <PSmall className="pb-2">Phone number {required}</PSmall>
        <div className="flex gap-x-2">
          <TPDSSelect
            className="w-[100px]"
            options={parsedCountries}
            labelProp="value"
            // @ts-ignore
            selectedIndex={selectedCountryCallingCode}
            //handleIndexSelection={(index: number) => handleMinAgeSelectChange(index)}
            handleIndexSelection={(index: number) => {
              // @ts-ignore
              setSelectedCountryCallingCode(index);
            }}
          />
          <NumberInput
            allowFloats={false}
            initialValue={undefined}
            maxValue={undefined}
            isInvalid={phoneNumberNameInvalid}
            placeholder="Your business phone number"
            onChangeCallback={(value: any) => setPhoneNumber(value)}
          />
        </div>
        {phoneNumberNameInvalid && (
          <PSmall className="pt-1 !text-tertiary">Please verify the phone number</PSmall>
        )}
        <br />
        <div className="flex justify-end">
          <TPDSButton
            onClick={createAccount}
            loading={isCreatingAccount}
            variant="info"
            label="Create Account"
          />
        </div>
        {/* TODO: Verify error data structure to display appropriate error messages */}
        {error ? (
          <PSmall className="py-2 !text-tertiary">
            An unknown error ocurred: ({JSON.stringify(error)})
          </PSmall>
        ) : null}
      </Window>
      {submitSuccess && successModal}
    </RootDiv>
  );
};
export default OnBoarding;
