export enum Orientation_Enum {
  NORTH = "NORTH",
  SOUTH = "SOUTH",
  EAST = "EAST",
  WEST = "WEST",
}

export enum PlacementType_Enum {
  QUAD = "QUAD",
  MAPPED = "MAPPED",
}
