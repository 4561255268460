import React from "react";
import { Placement } from "../types/types";
import { Button } from "@tempoplatform/tpds/components/buttons";
import { P } from "@tempoplatform/tpds/elements/typography";
import { useAppDispatch } from "../../../app/hooks";
import { addPlacement } from "../reducer";

type Props = {
  placements: Placement[] | null;
};

const PlacementsListHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const { placements } = props;
  if (!placements) return null;

  console.log("PlacementsListHeader - render");
  return (
    <div className="flex !w-full justify-between items-center mb-8">
      {placements && placements.length === 0 && (
        <P>This app has no placements yet. Click "New placement" to start creating.</P>
      )}
      {placements && placements.length > 0 && (
        <P>{`${placements.length} Placement${placements.length > 1 ? "s" : ""}`}</P>
      )}
      <Button
        className="ml-auto"
        variant="secondary"
        onClick={() => dispatch(addPlacement())}
        label="New placement"
        loading={false}
      />
    </div>
  );
};

export default PlacementsListHeader;
