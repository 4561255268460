import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useAppDispatch } from "../../app/hooks";
import { P, PLarge, PTiny } from "@tempoplatform/tpds/elements/typography";
import Tag from "@tempoplatform/tpds/components/tag";
import Table from "@tempoplatform/tpds/components/table";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { CampaignListItem, CampaignState, patchCampaignIsActive } from "../api/api";
import Modal from "@tempoplatform/tpds/components/modal";

interface Props {
  data: CampaignListItem[];
}

let renderDate = (timestamp: number) => {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "short" });
  return <span>{`${month} ${day}, ${year}`}</span>;
};

const getTagVariant = (campaignState: CampaignState) => {
  switch (campaignState) {
    case CampaignState.ACTIVE:
      return "success";
    case CampaignState.INACTIVE:
      return "warning";
    case CampaignState.UPCOMING:
      return "info";
    default:
      // ENDED
      return "default";
  }
};

const getColumns = (setCampaignToChange: any) => {
  const columns = [
    {
      label: "Id",
      propName: "id",
      key: "id",
      enableSort: true,
    },
    {
      label: "Name",
      propName: "name",
      key: "name",
      enableSort: true,
    },
    {
      label: "State",
      propName: "state",
      key: "state",
      enableSort: true,
      render: (item: any) => (
        <Tag label={item.state} variant={getTagVariant(item.state)} className="h-[19px]" />
      ),
    },
    {
      label: "Start Date",
      propName: "start_date",
      key: "start_date",
      enableSort: true,
      render: (item: any) => <PTiny>{renderDate(item.start_date)}</PTiny>,
    },
    {
      label: "End Date",
      propName: "end_date",
      key: "end_date",
      enableSort: true,
      render: (item: any) => <PTiny>{renderDate(item.end_date)}</PTiny>,
    },
    {
      label: "Actions",
      key: "activate_deactivate",
      align: "right",
      render: (record: CampaignListItem, index: number) => (
        <div className="flex">
          <Link to={`/campaign/view/${record.id}`} className="block">
            <Button className="mr-2 h-full shrink-0" variant="secondary">
              View
            </Button>
          </Link>
          <Button
            variant="secondary"
            className="w-[100px] shrink-0"
            onClick={() => setCampaignToChange(record)}
          >
            {record.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
      ),
    },
  ];
  return columns;
};

function getCampaignState(campaign: CampaignListItem) {
  const start = moment(campaign.start_date);
  const end = moment(campaign.end_date);
  if (moment().isAfter(end)) {
    return CampaignState.ENDED;
  }
  if (moment().isBetween(start, end)) {
    if (campaign.is_active) {
      return CampaignState.ACTIVE;
    }
    return CampaignState.INACTIVE;
  }
  if (moment().isBefore(start)) {
    return CampaignState.UPCOMING;
  }
}

const CampaignList: React.FC<Props> = ({ data }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [campaignToChange, setCampaignToChange] = React.useState<any>(null);
  // const [newCampaignModalOpen, setNewCampaignModalOpen] = React.useState<boolean>(false);
  // const [newCampaignType, setNewCampaignType] = React.useState<string>("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlPageParameter = params.get("page");
  const page = urlPageParameter ? parseInt(urlPageParameter) : 1;

  let dataSource: CampaignListItem[] = data.map((c: CampaignListItem) => {
    return { key: c.id, state: getCampaignState(c), ...c };
  });

  // sort array by id
  dataSource.sort((a, b) => {
    return a.id - b.id;
  });
  // show more recent first
  dataSource.reverse();

  const confirmModal = !campaignToChange ? null : (
    // @ts-ignore
    <Modal
      showOpen={true}
      title={
        campaignToChange && campaignToChange.is_active ? "Deactivate campaign" : "Activate campaign"
      }
      content={
        <P className="leading-normal">{`Are you sure you want to ${
          campaignToChange && campaignToChange.is_active ? "deactivate" : "activate"
        } this campaign?`}</P>
      }
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            patchCampaignIsActive(campaignToChange.id, !campaignToChange.is_active).then(() => {
              //todo: do a soft refresh to improve page load performance. Will need a central Campaigns redux state though.
              window.location.reload();
            });
          },
        },
        {
          label: "Cancel",
          variant: "secondary",
          callBack: () => setCampaignToChange(null),
        },
      ]}
    />
  );

  return (
    <div>
      <div>
        <PLarge isMedium className="mb-4">
          Standard Campaigns
        </PLarge>
        <Table
          columns={getColumns(setCampaignToChange)}
          data={dataSource}
          router={history}
          page={page}
          customHeaderClass="opacity-50"
        />
      </div>
      {campaignToChange && confirmModal}
    </div>
  );
};

export default CampaignList;
