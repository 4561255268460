import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CampaignList from "../campaignList/CampaignList";
import PlacementCampaignList from "../campaignList/PlacementCampaignList";
import clsx from "clsx";
import { useAppDispatch } from "../../app/hooks";
import Loading from "../loading/Loading";
import { P, PLarge, PSmall, PTiny } from "@tempoplatform/tpds/elements/typography";
import { getCampaigns, getPlacementCampaigns } from "../api/api";
import { useEffect, useState } from "react";
import Modal from "@tempoplatform/tpds/components/modal";
import Callout from "@tempoplatform/tpds/components/callout";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { resetCampaignCreate } from "../campaignCreate/campaignCreateSlice";
// import CampaignEmpty from "../campaignEmpty/CampaignEmpty";

const newCampaignButtonStyle = clsx(
  "select-none",
  "px-5 py-4 rounded-lg flex flex-col gap-y-1 cursor-pointer",
  "border-2 border-transparent",
  "bg-grey-light-scale-100 hover:bg-grey-light-scale-200",
  "hover:border-grey-light-scale-200",
  "dark:bg-grey-dark-scale-500 dark:hover:bg-grey-dark-scale-400",
  "dark:hover:border-grey-dark-scale-400",
);

interface Props {
  accountId: number;
}

const Campaigns: React.FC<Props> = ({ accountId }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [campaigns, setCampaigns] = useState<any>(null);
  const [placementCampaigns, setPlacementCampaigns] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [errorPlacement, setErrorPlacement] = useState<any>(null);
  const [newCampaignModalOpen, setNewCampaignModalOpen] = React.useState<boolean>(false);
  const [newCampaignType, setNewCampaignType] = React.useState<string>("");

  useEffect(() => {
    // ensure we only call getCampaigns if we are at the appropriate path
    if (location.pathname === "/") {
      history.push("/campaigns");
    } else {
      callGetCampaigns();
    }
  }, [accountId]);

  // render null if we aren't at the component's specific route
  if (location.pathname === "/") return null;

  const callGetCampaigns = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    setLoading(true);
    try {
      const response = await getCampaigns(id_token || "", accountId);
      setCampaigns(response);
    } catch (error: any) {
      setError(error);
    }

    try {
      const response2 = await getPlacementCampaigns(id_token || "");
      setPlacementCampaigns(response2);
    } catch (error: any) {
      setErrorPlacement(error);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  const noCampaigns = !error && campaigns.length === 0;
  const noPlacementCampaigns = !errorPlacement && placementCampaigns.length === 0;

  const newCampaignModal = (
    <Modal
      showOpen={true}
      title="New campaign"
      description="Please select campaign type"
      content={
        <div className="flex flex-col gap-y-2">
          <div
            onClick={() => setNewCampaignType("brand_awareness")}
            className={clsx(
              newCampaignButtonStyle,
              newCampaignType === "brand_awareness" && "border-2 !border-black dark:!border-white",
            )}
          >
            <P isBold className="leading-none">
              Brand awareness
            </P>
            <PSmall className="text-secondary leading-none">
              Brand awareness campaign type description
            </PSmall>
          </div>
          <div
            onClick={() => setNewCampaignType("placement")}
            className={clsx(
              newCampaignButtonStyle,
              newCampaignType === "placement" && "border-2 !border-black dark:!border-white",
            )}
          >
            <P isBold className="leading-none">
              Placement
            </P>
            <PSmall className="text-secondary leading-none">
              Placement campaign type description
            </PSmall>
          </div>
          <div
            onClick={() => setNewCampaignType("shoppable")}
            className={clsx(
              newCampaignButtonStyle,
              newCampaignType === "shoppable" && "border-2 !border-black dark:!border-white",
            )}
          >
            <P isBold className="leading-none">
              Shoppable
            </P>
            <PSmall className="text-secondary leading-none">
              Shoppable campaign type description
            </PSmall>
          </div>
        </div>
      }
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            if (newCampaignType === "") {
              return;
            }
            dispatch(resetCampaignCreate());
            if (newCampaignType === "brand_awareness" || newCampaignType === "shoppable") {
              history.push("/campaigns/create");
            }
            if (newCampaignType === "placement") {
              history.push("/campaigns/placement/create");
            }
          },
        },
        {
          label: "Cancel",
          variant: "secondary",
          callBack: () => setNewCampaignModalOpen(false),
        },
      ]}
    />
  );

  return (
    <>
      <div className="flex flex-col gap-6 mb-20">
        <Button
          className="mb-0 ml-auto"
          variant="info"
          onClick={() => {
            // dispatch(resetCampaignCreate());
            // history.push("/campaigns/create");
            setNewCampaignModalOpen(true);
            setNewCampaignType("");
          }}
        >
          Create Campaign
        </Button>
        <CampaignList data={noCampaigns ? [] : campaigns} />
        {error && (
          <Callout variant="danger" title="Error" text={"Campaigns request: " + error.message} />
        )}
        {!noPlacementCampaigns && (
          <PlacementCampaignList
            data={noPlacementCampaigns ? [] : placementCampaigns}
            onUpdate={callGetCampaigns}
          />
        )}
        {errorPlacement && (
          <Callout
            variant="danger"
            title="Error"
            text={"Placement campaigns request: " + errorPlacement.message}
          />
        )}
      </div>

      {newCampaignModalOpen && newCampaignModal}
    </>
  );
};

export default Campaigns;
