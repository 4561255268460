import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { PLarge, PSmall } from "@tempoplatform/tpds/elements/typography";
import { toGetAccount } from "./api/api";
import ColorModeControl from "../color/ThemeControl";
import Loading from "./loading/Loading";
import clsx from "clsx";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

const headerClass = clsx(
  "w-full h-12 px-4 gap-x-4",
  "bg-window border-b border-grey-light-scale-200 dark:border-grey-dark-scale-200 z-20",
  "flex flex-row justify-between items-center",
);

const RootDiv = styled.div`
    .nav-link {
        position: relative;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    .nav-link.active {
        p {
            font-weight: 500;
        }

        :before {
            background: var(--blue-500);
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 3px;
            color: green;
        }
`;

// @ts-ignore
const Navbar = () => {
  const location = useLocation();
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const { getIdTokenClaims } = useAuth0();
  const [currentTab, setCurrentTab] = React.useState(
    location.pathname.includes("reports") ? "reports" : "campaigns",
  );

  const userId = useAuth0()?.user?.sub as string;
  const isAuthenticated = useAuth0().isAuthenticated as Boolean;
  const isVerified = useAuth0()?.user?.email_verified as Boolean;
  const isLoading = useAuth0().isLoading as Boolean;

  useEffect(() => {
    if (userId) {
      getAccountInformation();
    }
  }, [userId]);

  const getAccountInformation = async () => {
    //const domain = process.env.REACT_APP_AUTH0_DOMAIN;

    const claims: IdToken | undefined = await getIdTokenClaims();
    const id_token: string | undefined = claims?.__raw;

    if (!id_token) {
      setError("Failed to get valid Auth0 token.");
      return;
    }
    try {
      const response = await toGetAccount(userId, id_token);
      setData(response);
    } catch (error: any) {
      setError(error);
    }
  };

  // Logs out of Auth0-linked accounts and returns to Auth0 login portal
  const { logout } = useAuth0();
  const logoutWithAuth0 = () => {
    console.log(`Logging out to: ${window.location.origin}`);
    logout({ returnTo: window.location.origin });
  };

  // TODO: UX for handling this
  if (useAuth0() === null) {
    return null;
  }

  const reportsLink = (
    <NavLink className="nav-link px-2" to="/reports" onClick={() => setCurrentTab("reports")}>
      <PSmall className="select-none font-medium">Reports</PSmall>
    </NavLink>
  );
  const apiKeysLink = (
    <NavLink className="nav-link px-2" to="/api-keys" onClick={() => setCurrentTab("reports")}>
      <PSmall className="select-none font-medium">Api Keys</PSmall>
    </NavLink>
  );
  // Doesn't need landing page anymore so just go to loading'
  if (!isLoading && !isAuthenticated) {
    return <Loading />;
  } else {
    return (
      <RootDiv className={headerClass}>
        <div className="flex items-center" style={{ height: "100%" }}>
          <a className="flex items-center gap-x-2 mr-8 relative top-[-1px]" href="/">
            <img className="logo" src={"/logo.png"} alt="Tempo logo" />
            <PLarge isBold>TEMPO</PLarge>
          </a>
          {isAuthenticated && data && data.account_type_id === "ADVERTISER" && (
            <>
              <NavLink
                className="nav-link px-2"
                to="/campaigns"
                onClick={() => setCurrentTab("campaigns")}
              >
                <PSmall className="select-none font-medium">Campaigns</PSmall>
              </NavLink>
              {reportsLink}
              {apiKeysLink}
            </>
          )}
          {isAuthenticated && data && data.account_type_id === "PUBLISHER" && (
            <>
              <NavLink
                className="nav-link px-2"
                to="/pub/applications"
                onClick={() => setCurrentTab("apps")}
              >
                <PSmall className="select-none font-medium">My Apps</PSmall>
              </NavLink>
              {reportsLink}
              {apiKeysLink}
            </>
          )}
        </div>
        <div className="flex items-center gap-x-4">
          <ColorModeControl />
          {isAuthenticated && (
            <Button className="ml-2" variant="secondary" onClick={logoutWithAuth0}>
              Logout
            </Button>
          )}
        </div>
      </RootDiv>
    );
  }
};

export default Navbar;
