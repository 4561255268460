import React from "react";
import { P, PLarge } from "@tempoplatform/tpds/elements/typography";
import { Button } from "@tempoplatform/tpds/components/buttons";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { resetState } from "../reducer";
import ColorModeControl from "../../../color/ThemeControl";
import { Cross } from "@tempoplatform/tpds/assets/svgs/tiny";
import clsx from "clsx";

const TopBar = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handlePlacementCreateCancel = () => {
    console.log("handlePlacementCreateCancel");
    dispatch(resetState());
    history.push("/campaigns");
  };

  const handleCampaignSave = () => {
    console.log("handleCampaignSave");
    // TODO - save campaign
    props.createCampaign();
  };

  const classTopBar = clsx(
    `h-[50px] w-full px-4`,
    `justify-between flex items-center`,
    `bg-window border-b border-grey-light-scale-200 dark:border-grey-dark-scale-200`,
  );

  console.log("TopBar - render");
  return (
    <div className={classTopBar}>
      <div className="flex items-center relative top-[-1px]">
        <img className="logo" src={"/logo.png"} alt="Tempo logo" />
        <PLarge isBold className="!ml-2 leading-none">
          TEMPO
        </PLarge>
      </div>
      <P isMedium className="leading-none relative top-[-2px]">
        Placement Campaign edit mode
      </P>
      <div className="flex items-center gap-x-4">
        <ColorModeControl />

        <div className="flex items-center gap-x-1.5">
          <Button
            label={props.campaignId ? "Close" : "Cancel"}
            variant="secondary"
            iconAfter={<Cross />}
            onClick={handlePlacementCreateCancel}
          />
          {/*<Button label="Save" variant="info" iconAfter={<Icon />} onClick={handleCampaignSave} />*/}
        </div>
      </div>
    </div>
  );
};

export default TopBar;

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="lucide lucide-save"
      viewBox="0 0 24 24"
    >
      <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"></path>
      <path d="M17 21L17 13 7 13 7 21"></path>
      <path d="M7 3L7 8 15 8"></path>
    </svg>
  );
}
