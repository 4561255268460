import React, { useState, useEffect } from "react";
import { Placement } from "./types/types";
import { useAuth0 } from "@auth0/auth0-react";
import { PageRoot, Container } from "@tempoplatform/tpds/elements/layout";
import { PLarge, PSmall, PTiny } from "@tempoplatform/tpds/elements/typography";
import PlacementsListItem from "./subcomponents/PlacementsListItem";
import PlacementsListHeader from "./subcomponents/PlacementsListHeader";
import { useHistory } from "react-router-dom";
import TopBar from "./subcomponents/TopBar";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { getPlacementGroup, toPostPlacement, getPlacements } from "../api/api";
import { useLocation, useParams } from "react-router-dom";
import { loadPlacements } from "./reducer";
import Modal from "@tempoplatform/tpds/components/modal";

const Placements: React.FC = () => {
  console.log("Placements - render");
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // @ts-ignore
  const { appId } = useParams();
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>(false);

  const placements = useAppSelector(state => state.placementsCreate.placements);
  console.log("placements", placements);

  const [currentListItem, setCurrentListItem] = useState<number | undefined>(0);

  React.useEffect(() => {
    getPlacementGroupAndStore();
  }, [appId]);

  const getPlacementGroupAndStore = async () => {
    //const domain = process.env.REACT_APP_AUTH0_DOMAIN;

    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;

    try {
      const placementGroupResponse = await getPlacementGroup(appId, id_token);
      const placementsResponse = await getPlacements(placementGroupResponse.id, id_token);
      // Temporary patch for incorrect API response
      for (var i = 0; i < placementsResponse.length; i++) {
        if (placementsResponse[i].placement_coordinate) {
          placementsResponse[i].placement_coordinates = placementsResponse[i].placement_coordinate;
          delete placementsResponse[i].placement_coordinate;
        }
      }
      dispatch(loadPlacements(placementsResponse));
    } catch (error: any) {
      throw new Error(error);
    }
  };
  const successModal = (
    // @ts-ignore
    <Modal
      showOpen={true}
      title="Placement created!"
      content={<PSmall>Awesome! Your Placement is now created. 🥳</PSmall>}
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            history.push("/pub/applications");
          },
        },
      ]}
    />
  );

  const createCampaign = async () => {
    if (!appId) {
      return false;
    }
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    const data = {};
    try {
      const response = await toPostPlacement(appId, placements, id_token);
      if (response.error) {
        //do something with the response?
      } else {
        // show save success
        setSubmitSuccess(true);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };
  return (
    <PageRoot className="fixed top-0 left-0 z-30">
      <TopBar createCampaign={createCampaign} />
      <div className="flex gap-0 w-full">
        <div
          className="flex flex-col p-6 bg-grey-dark-scale-800"
          style={{ height: "calc(100vh - 50px)", width: "400px", overflowY: "scroll" }}
        >
          <PlacementsListHeader placements={placements as Placement[]} />
          {placements?.map((placement: Placement, index: number) => (
            <PSmall
              key={index}
              className={
                index === currentListItem
                  ? "mb-2 cursor-pointer"
                  : "mb-2 cursor-pointer text-secondary"
              }
              onClick={() => setCurrentListItem(index)}
            >
              {placement.custom_name || "Unnamed Placement"}
            </PSmall>
          ))}
        </div>
        <div className="overflow-y-scroll w-full px-20" style={{ height: "calc(100vh - 50px)" }}>
          {placements?.map((placement: Placement, index: number) => {
            if (index === currentListItem)
              return <PlacementsListItem key={index} placement={placement} />;
          })}
        </div>
      </div>
      {submitSuccess && successModal}
    </PageRoot>
  );
};

export default Placements;
