// DUCKS pattern
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AdTypes, Currency_Enum } from "../../constants/enums";
import moment from "moment";
import { determineVariantData } from "./helpers/campaignViewer";
import {
  FormFields,
  BaseProductReviewInfo,
  ProductReviewData,
  ProductVariantBoxTitleData,
  ProductVariantBoxValuesData,
  // InfoBox,
  ShippingInfoData,
  ProductInfoBoxData,
  RemoveInfoBoxInput,
  RemoveVariantBoxInput,
  ProductImageListInput,
  ProductImage,
  ProductNameInput,
  ProductPriceInput,
  VariantBoxBooleanValue,
  Product,
  BackgroundColorInput,
  CampaignCreateState,
} from "../../constants/interfaces";

const bar5DefaultValue = 90;
const bar4DefaultValue = 50;
const bar3DefaultValue = 15;
const bar2DefaultValue = 3;
const bar1DefaultValue = 0;

export const initialState: CampaignCreateState = {
  currentStep: 0,
  infoBoxes: [[], [], [], []],
  variantBoxes: [[], [], [], []],
  productImages: [[], [], [], []],
  shippingOptions: [[], [], [], []],
  productReviews: [[], [], [], []],
  products: [
    {
      add_reviews: false,
      review_chart_bar_5_percent: bar5DefaultValue,
      review_chart_bar_4_percent: bar4DefaultValue,
      review_chart_bar_3_percent: bar3DefaultValue,
      review_chart_bar_2_percent: bar2DefaultValue,
      review_chart_bar_1_percent: bar1DefaultValue,
    },
    {
      add_reviews: false,
      review_chart_bar_5_percent: bar5DefaultValue,
      review_chart_bar_4_percent: bar4DefaultValue,
      review_chart_bar_3_percent: bar3DefaultValue,
      review_chart_bar_2_percent: bar2DefaultValue,
      review_chart_bar_1_percent: bar1DefaultValue,
    },
    {
      add_reviews: false,
      review_chart_bar_5_percent: bar5DefaultValue,
      review_chart_bar_4_percent: bar4DefaultValue,
      review_chart_bar_3_percent: bar3DefaultValue,
      review_chart_bar_2_percent: bar2DefaultValue,
      review_chart_bar_1_percent: bar1DefaultValue,
    },
    {
      add_reviews: false,
      review_chart_bar_5_percent: bar5DefaultValue,
      review_chart_bar_4_percent: bar4DefaultValue,
      review_chart_bar_3_percent: bar3DefaultValue,
      review_chart_bar_2_percent: bar2DefaultValue,
      review_chart_bar_1_percent: bar1DefaultValue,
    },
  ],
  lastProductIndex: 0,
  topHexColor: "#FFFFFF",
  topOffset: "0.00",
  bottomHexColor: "#DDDDDD",
  bottomOffset: "1.00",
  isAdvancedConfig: false,
  targeting_gender: "All",
  targeting_age: { min: 13, max: 99 },
  targeting_locations: [],
  targeting_interests: [],
  targeting_languages: [],
  targeting_goal: "Purchase",
  targeting_bid_type: 0,
  budget_daily: 0,
  budget_cpm: 0,
  start_end_dates: [],
  rewarded_interstitial: "Rewarded",
  preview_selectedProduct: 0,
  preview_selectedProductReviews: 0,
  ad_type: AdTypes.shoppable,
  cta_label: "",
  preview_brandAwarenessDialogOpen: false,
  enumsData: null,
  topIslandBackgroundColor: "#1e222466",
  topIslandTextColor: "#ffffff",
  bottomIslandBackgroundColor: "#dededeb5",
  bottomIslandThumbnailsBackgroundColor: "#ffffffc9",
  bottomIslandThumbnailsPadding: 0,
  previewCampaignData: null,
  // images_and_form ad type
  formFields: {
    model: false,
    model_label: "",
    custom_fields: [],
    formHeadline: "Register your interest below",
  },
  // redirect_to_webpage ad type
  redirect_url: "",
  redirect_to_webpage_image_0: "",
  redirect_to_webpage_url_0: "",
  redirect_to_webpage_image_1: "",
  redirect_to_webpage_url_1: "",
  redirect_to_webpage_image_2: "",
  redirect_to_webpage_url_2: "",
  redirect_to_webpage_image_3: "",
  redirect_to_webpage_url_3: "",
};

const campaignCreateSlice = createSlice({
  name: "campaignCreate",
  initialState,
  reducers: {
    initializeCampaignCreate: (state, action: PayloadAction<any>) => {
      state.campaignName = action.payload.campaignName;
      state.brandName = action.payload.brandName;
      state.headline = action.payload.headline;
      state.mainImage = action.payload.mainImage;
      state.video = action.payload.video;
      state.isAdvancedConfig = action.payload.isAdvancedConfig;
      state.logoImage = action.payload.logoImage;
      state.infoBoxes = action.payload.infoBoxes;
      state.variantBoxes = action.payload.variantBoxes;
      state.productImages = action.payload.productImages;
      state.products = action.payload.products;
      state.topHexColor = action.payload.topHexColor;
      state.bottomHexColor = action.payload.bottomHexColor;
      state.targeting_age = action.payload.targeting_age;
      state.targeting_gender = action.payload.targeting_gender;
      state.targeting_locations = action.payload.targeting_locations;
      state.targeting_interests = action.payload.targeting_interests;
      state.targeting_languages = action.payload.targeting_languages;
      state.targeting_goal = action.payload.targeting_goal;
      state.targeting_bid_type = action.payload.targeting_bid_type;
      state.budget_daily = action.payload.budget_daily;
      state.budget_cpm = action.payload.budget_cpm;
      state.start_end_dates = action.payload.start_end_dates;
      state.rewarded_interstitial = action.payload.rewarded_interstitial;
      state.shippingOptions = action.payload.shippingOptions;
      state.productReviews = action.payload.productReviews;
      state.ad_type = action.payload.ad_type;
      state.cta_label = action.payload.cta_label;
      state.preview_selectedProduct = 0;
      state.preview_selectedProductReviews = 0;
      state.preview_brandAwarenessDialogOpen = false;
      // images_and_form ad type
      state.formFields = action.payload.formFields;
    },
    resetCampaignCreate: () => initialState,
    updateFormData(state, action: PayloadAction<FormFields>) {
      state.formFields = action.payload;
    },
    setEnumsData(state, action: PayloadAction<any>) {
      state.enumsData = action.payload;
    },
    setCampaignName(state, action: PayloadAction<string>) {
      state.campaignName = action.payload;
    },
    setBrandName(state, action: PayloadAction<string>) {
      state.brandName = action.payload;
    },
    setHeadline(state, action: PayloadAction<string>) {
      state.headline = action.payload;
    },
    setMainImage(state, action: PayloadAction<string | undefined>) {
      state.mainImage = action.payload;
    },
    setVideo(state, action: PayloadAction<any>) {
      state.video = action.payload;
    },
    updateProductReviewCount(state, action: PayloadAction<BaseProductReviewInfo>) {
      state.products[action.payload.productIndex].review_count = action.payload.value;
    },
    updateProductReviewOverallScore(state, action: PayloadAction<BaseProductReviewInfo>) {
      state.products[action.payload.productIndex].review_overall_score = action.payload.value;
    },
    updateProductReviewPercentRecommended(state, action: PayloadAction<BaseProductReviewInfo>) {
      state.products[action.payload.productIndex].review_percent_recommended = action.payload.value;
    },
    updateProductReviewChartBar(state, action: PayloadAction<any>) {
      // @ts-ignore
      state.products[action.payload.productIndex][
        `review_chart_bar_${action.payload.barNumber}_percent`
      ] = action.payload.value;
    },
    setLogoImage(state, action: PayloadAction<string | undefined>) {
      state.logoImage = action.payload;
    },
    updateTopIslandBackgroundColor(state, action: PayloadAction<string>) {
      state.topIslandBackgroundColor = action.payload;
    },
    updateTopIslandTextColor(state, action: PayloadAction<string>) {
      state.topIslandTextColor = action.payload;
    },
    updateBottomIslandBackgroundColor(state, action: PayloadAction<string>) {
      state.bottomIslandBackgroundColor = action.payload;
    },
    updateBottomIslandThumbnailsBackgroundColor(state, action: PayloadAction<string>) {
      state.bottomIslandThumbnailsBackgroundColor = action.payload;
    },
    updateBottomIslandThumbnailsPadding(state, action: PayloadAction<number>) {
      state.bottomIslandThumbnailsPadding = action.payload;
    },
    setPaletteColors(state, action: PayloadAction<BackgroundColorInput>) {
      state.topHexColor = action.payload.topHexColor;
      state.topOffset = action.payload.topOffset;
      state.bottomHexColor = action.payload.bottomHexColor;
      state.bottomOffset = action.payload.bottomOffset;
    },
    setProductPrice(state, action: PayloadAction<ProductPriceInput>) {
      state.products[action.payload.productIndex].price = action.payload.price;
      state.lastProductIndex = action.payload.productIndex;
    },
    setProductName(state, action: PayloadAction<ProductNameInput>) {
      state.products[action.payload.productIndex].name = action.payload.name;
      state.lastProductIndex = action.payload.productIndex;
    },
    setIsAdvancedConfig(state, action: PayloadAction<boolean>) {
      state.isAdvancedConfig = action.payload;
    },
    setProductImageList(state, action: PayloadAction<ProductImageListInput>) {
      state.productImages[action.payload.productIndex] = action.payload.imageList;
      state.lastProductIndex = action.payload.productIndex;
    },
    addProductImage(state, action: PayloadAction<ProductImage>) {
      state.productImages[action.payload.productIndex].push(action.payload.newImage);
      state.lastProductIndex = action.payload.productIndex;
    },
    incrementStep(state) {
      state.currentStep += 1;
    },
    decrementStep(state) {
      state.currentStep -= 1;
    },
    updateInfoBoxTitle(state, action: PayloadAction<ProductInfoBoxData>) {
      state.infoBoxes[action.payload.productIndex][action.payload.infoBoxIndex].title =
        action.payload.value;
      state.lastProductIndex = action.payload.productIndex;
    },
    createNewProductReview(state, action: PayloadAction<any>) {
      state.productReviews[action.payload.productIndex].push({
        stars: 5,
        username: "",
        title: "",
        description: "",
        // @ts-ignore
        date: action.payload.date,
      });
    },
    updateProductReview(state, action: PayloadAction<ProductReviewData>) {
      state.productReviews[action.payload.productIndex][action.payload.productReviewIndex] =
        action.payload.value;
    },
    removeProductReview(state, action: PayloadAction<ProductReviewData>) {
      state.productReviews[action.payload.productIndex].splice(
        action.payload.productReviewIndex,
        1,
      );
    },
    createNewShippingOption(state, action: PayloadAction<any>) {
      state.shippingOptions[action.payload.productIndex].push({
        display_name: "",
        amount: 0,
        currency: Currency_Enum.Usd,
        del_est_min_unit: "Business Day",
        del_est_min_value: 2,
        del_est_max_unit: "Business Day",
        del_est_max_value: 4,
        regional_availabilities: [],
      });
    },
    updateShippingOption(state, action: PayloadAction<ShippingInfoData>) {
      state.shippingOptions[action.payload.productIndex][action.payload.shippingOptionIndex] =
        action.payload.value;
    },
    removeShippingOption(state, action: PayloadAction<ShippingInfoData>) {
      state.shippingOptions[action.payload.productIndex].splice(
        action.payload.shippingOptionIndex,
        1,
      );
    },
    updateVariantTitle(state, action: PayloadAction<ProductVariantBoxTitleData>) {
      state.variantBoxes[action.payload.productIndex][action.payload.variantIndex].title =
        action.payload.value;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateVariantSelectedValue(state, action: PayloadAction<any>) {
      state.variantBoxes[action.payload.productIndex][
        action.payload.variantIndex
      ].preview_selectedValueIndex = action.payload.value;
    },
    updateVariantValues(state, action: PayloadAction<ProductVariantBoxValuesData>) {
      state.variantBoxes[action.payload.productIndex][action.payload.variantIndex].values =
        action.payload.values;
      state.lastProductIndex = action.payload.productIndex;
    },
    toggleVariantSetIndices(state, action: PayloadAction<VariantBoxBooleanValue>) {
      state.variantBoxes[action.payload.productIndex][action.payload.variantIndex].set_indices =
        action.payload.value;
    },
    updateInfoBoxDescription(state, action: PayloadAction<ProductInfoBoxData>) {
      state.infoBoxes[action.payload.productIndex][action.payload.infoBoxIndex].description =
        action.payload.value;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingAge(state, action: PayloadAction<any>) {
      state.targeting_age = {
        min: action.payload.min,
        max: action.payload.max,
      };
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingGender(state, action: PayloadAction<any>) {
      state.targeting_gender = action.payload;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingLocations(state, action: PayloadAction<any>) {
      state.targeting_locations = action.payload;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingInterests(state, action: PayloadAction<any>) {
      state.targeting_interests = action.payload;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingLanguages(state, action: PayloadAction<any>) {
      state.targeting_languages = action.payload;
      state.lastProductIndex = action.payload.productIndex;
    },
    updateTargetingGoal(state, action: PayloadAction<any>) {
      state.targeting_goal = action.payload;
    },
    updateTargetingBidType(state, action: PayloadAction<any>) {
      state.targeting_bid_type = action.payload;
    },
    updateDailyBudget(state, action: PayloadAction<any>) {
      state.budget_daily = action.payload;
    },
    updateCpmBudget(state, action: PayloadAction<any>) {
      state.budget_cpm = action.payload;
    },
    updateAdType(state, action: PayloadAction<AdTypes>) {
      state.ad_type = action.payload;
    },
    updateCtaLabel(state, action: PayloadAction<string>) {
      state.cta_label = action.payload;
    },
    updateStartEndDateValues(state, action: PayloadAction<any>) {
      state.start_end_dates = action.payload;
    },
    updateRewardedInterstitial(state, action: PayloadAction<any>) {
      state.rewarded_interstitial = action.payload;
    },
    updateAddReviews(state, action: PayloadAction<any>) {
      state.products[action.payload.productIndex].add_reviews = action.payload.value;
    },
    addNewInfoBox(state, action: PayloadAction<number>) {
      state.infoBoxes[action.payload].push({});
      state.lastProductIndex = action.payload;
    },
    addNewVariantBox(state, action: PayloadAction<number>) {
      state.variantBoxes[action.payload].push({});
      state.lastProductIndex = action.payload;
    },
    addNewInfoBoxWithTitle(state, action: PayloadAction<any>) {
      state.infoBoxes[action.payload.productIndex].push({
        title: action.payload.title,
        description: "",
      });
      state.lastProductIndex = action.payload;
    },
    addNewVariantBoxWithTitle(state, action: PayloadAction<any>) {
      state.variantBoxes[action.payload.productIndex].push({
        title: action.payload.title,
        values: [],
        set_indices: false,
        preview_selectedValueIndex: 0,
      });
      state.lastProductIndex = action.payload.productIndex;
    },
    removeInfoBox(state, action: PayloadAction<RemoveInfoBoxInput>) {
      state.infoBoxes[action.payload.productIndex].splice(action.payload.infoBoxIndex, 1);
    },
    removeVariantBox(state, action: PayloadAction<RemoveVariantBoxInput>) {
      state.variantBoxes[action.payload.productIndex].splice(action.payload.variantIndex, 1);
    },
    selectPreviewProduct(state, action: PayloadAction<number>) {
      state.preview_selectedProduct = action.payload;
    },
    deselectPreviewProduct(state) {
      state.preview_selectedProduct = 0;
    },
    selectPreviewProductReviews(state, action: PayloadAction<number>) {
      state.preview_selectedProductReviews = action.payload;
    },
    deselectPreviewProductReviews(state) {
      state.preview_selectedProductReviews = 0;
    },
    setPreviewBrandAwarenessDialogOpen(state, action: PayloadAction<boolean>) {
      state.preview_brandAwarenessDialogOpen = action.payload;
    },
    setRedirectUrl(state, action: PayloadAction<any>) {
      state.redirect_url = action.payload;
    },
    setRedirectImage0(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_image_0 = action.payload;
    },
    setRedirectImage1(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_image_1 = action.payload;
    },
    setRedirectImage2(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_image_2 = action.payload;
    },
    setRedirectImage3(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_image_3 = action.payload;
    },
    setRedirectUrl0(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_url_0 = action.payload;
    },
    setRedirectUrl1(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_url_1 = action.payload;
    },
    setRedirectUrl2(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_url_2 = action.payload;
    },
    setRedirectUrl3(state, action: PayloadAction<any>) {
      state.redirect_to_webpage_url_3 = action.payload;
    },
    insertPreviewData(state, action: PayloadAction<any>) {
      /*
       *  This function is only used to insert the necessary campaign data
       *  to be consumed by src/components/campaign/CampaignView.tsx
       *  to preview a campaign.
       *
       *  When existing CampaignView, this state is reset
       */

      const data: any = action.payload;

      state.campaignName = data.name;
      state.brandName = data.brand_name;
      state.headline = data.headline;
      state.mainImage = data.image_url;
      state.video = data.video_url;
      state.ad_type =
        data.ad_type === "brand_awareness" ? AdTypes.brand_awareness : AdTypes.shoppable;

      if (data.ad_style) {
        state.topIslandBackgroundColor = data.ad_style.top_island.background_color;
        state.topIslandTextColor = data.ad_style.top_island.text_color;
        state.bottomIslandBackgroundColor = data.ad_style.bottom_island.background_color;
        state.bottomIslandThumbnailsBackgroundColor =
          data.ad_style.bottom_island.thumbnails_background_color;
        state.bottomIslandThumbnailsPadding = data.ad_style.bottom_island.thumbnails_padding;
      }

      const previewProducts = [];
      const previewProductReviews = [];
      const previewVariants = [[], [], [], []];
      for (let productIndex = 0; productIndex < 4; productIndex++) {
        const product = data.campaign_products[productIndex].product;
        // base
        previewProducts.push({
          name: product.name,
          price: product.price,
          add_reviews: true,
          review_chart_bar_5_percent: product.product_review_scores.bar_5,
          review_chart_bar_4_percent: product.product_review_scores.bar_4,
          review_chart_bar_3_percent: product.product_review_scores.bar_3,
          review_chart_bar_2_percent: product.product_review_scores.bar_2,
          review_chart_bar_1_percent: product.product_review_scores.bar_1,
          review_count: product.product_review_scores.count,
          review_overall_score: product.product_review_scores.score,
          review_percent_recommended: product.product_review_scores.percent_recommended,
        });
        // reviews
        state.productReviews[productIndex] = [];
        if (product.product_reviews && product.product_reviews.length > 0) {
          const thisProductReviews = product.product_reviews.map((review: any) => {
            return {
              stars: review.star_rating,
              username: review.user_name,
              title: review.title,
              description: review.content,
              date: moment(review.created_timestamp).format("DD MMM YYYY"),
            };
          });
          previewProductReviews.push(thisProductReviews);
        }
        // images
        state.productImages[productIndex] = product.image_urls.map((url: string) => {
          return {
            uid: url,
            url: url,
          };
        });
        // info boxes
        const infoBoxesObject = product.info_boxes;
        const infoBoxes = [];
        for (let ib = 0; ib < Object.keys(infoBoxesObject).length; ib++) {
          const key = Object.keys(infoBoxesObject)[ib];
          infoBoxes.push({
            title: key,
            description: infoBoxesObject[Object.keys(infoBoxesObject)[ib]],
          });
        }
        state.infoBoxes[productIndex] = infoBoxes;
        // shipping options
        state.shippingOptions[productIndex] = product.campaign_product_shipping_options.map(
          (option: any) => {
            const shipping_option = option.shipping_option;
            const regional_availabilities = shipping_option.regional_availabilities.map(
              (ra: any) => {
                return ra.region;
              },
            );
            return {
              display_name: shipping_option.display_name,
              amount: shipping_option.amount,
              currency: shipping_option.currency,
              del_est_min_unit: shipping_option.del_est_min_unit,
              del_est_min_value: shipping_option.del_est_min_value,
              del_est_max_unit: shipping_option.del_est_max_unit,
              del_est_max_value: shipping_option.del_est_max_value,
              regional_availabilities: regional_availabilities,
            };
          },
        );

        previewVariants[productIndex] = product.product_variants.map((variant: any) => {
          const variantData = determineVariantData(variant, product);
          return {
            title: variantData.name,
            values: variantData.values,
            set_indices: variantData.set_indices,
            preview_selectedValueIndex: 0,
          };
        });
      }

      state.products = previewProducts;
      state.productReviews = previewProductReviews;
      state.variantBoxes = previewVariants;
    },
  },
});

export const {
  initializeCampaignCreate,
  resetCampaignCreate,
  setCampaignName,
  setBrandName,
  setHeadline,
  setLogoImage,
  setMainImage,
  setVideo,
  incrementStep,
  decrementStep,
  updateInfoBoxTitle,
  updateVariantTitle,
  updateInfoBoxDescription,
  addNewInfoBox,
  addNewInfoBoxWithTitle,
  addNewVariantBox,
  addNewVariantBoxWithTitle,
  removeInfoBox,
  removeVariantBox,
  setProductImageList,
  addProductImage,
  setProductPrice,
  setProductName,
  setIsAdvancedConfig,
  setPaletteColors,
  updateVariantValues,
  toggleVariantSetIndices,
  updateTargetingAge,
  updateTargetingGender,
  updateTargetingLocations,
  updateTargetingInterests,
  updateTargetingLanguages,
  updateTargetingGoal,
  updateTargetingBidType,
  updateDailyBudget,
  updateCpmBudget,
  updateStartEndDateValues,
  updateRewardedInterstitial,
  deselectPreviewProduct,
  updateVariantSelectedValue,
  updateAdType,
  updateCtaLabel,
  selectPreviewProduct,
  selectPreviewProductReviews,
  setPreviewBrandAwarenessDialogOpen,
  createNewShippingOption,
  updateShippingOption,
  removeShippingOption,
  setEnumsData,
  createNewProductReview,
  updateProductReview,
  removeProductReview,
  updateProductReviewCount,
  updateProductReviewOverallScore,
  updateProductReviewPercentRecommended,
  updateProductReviewChartBar,
  updateAddReviews,
  updateTopIslandBackgroundColor,
  updateTopIslandTextColor,
  updateBottomIslandBackgroundColor,
  updateBottomIslandThumbnailsBackgroundColor,
  updateBottomIslandThumbnailsPadding,
  insertPreviewData,
  updateFormData,
  setRedirectUrl,
  setRedirectImage0,
  setRedirectImage1,
  setRedirectImage2,
  setRedirectImage3,
  setRedirectUrl0,
  setRedirectUrl1,
  setRedirectUrl2,
  setRedirectUrl3,
} = campaignCreateSlice.actions;
export default campaignCreateSlice.reducer;

// Export selectors here
export const selectPaletteColors = (state: RootState) => [
  {
    offset: state.campaignCreate.topOffset,
    color: state.campaignCreate.topHexColor,
  },
  {
    offset: state.campaignCreate.bottomOffset,
    color: state.campaignCreate.bottomHexColor,
  },
];
