import tokens from "@tempoplatform/tpds/tokens";
const grey_dark = tokens.colors.color_palettes.grey_dark;
const grey_light = tokens.colors.color_palettes.grey_light;

export const getChartConfig = (currentTheme: string) => {
  return {
    grid: {
      line: {
        stroke: currentTheme === "light" ? grey_light["400"] : grey_dark["400"],
        strokeWidth: 1,
      },
    },
    legends: {
      text: {
        fill: currentTheme === "light" ? grey_dark["400"] : grey_light["400"],
      },
    },
    axis: {
      ticks: {
        line: {
          stroke: currentTheme === "light" ? grey_light["400"] : grey_dark["400"],
        },
        text: {
          fill: currentTheme === "light" ? grey_dark["400"] : grey_light["400"],
        },
      },
      legend: {
        text: {
          fill: currentTheme === "light" ? grey_dark["400"] : grey_light["400"],
        },
      },
    },
    crosshair: {
      line: {
        stroke: currentTheme === "light" ? grey_dark["400"] : grey_light["400"],
        strokeWidth: 1,
        strokeDasharray: "6 6",
      },
    },
  };
};
