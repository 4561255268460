import styled from "@emotion/styled";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import clsx from "clsx";

const classElementRoot = clsx(`w-full aspect-square`, `border-b border-window`);

const classEmptyElementRoot = clsx(
  `w-full aspect-square`,
  `border-b border-window`,
  `flex flex-col justify-center items-center`,
);

const RootDiv = styled.div`
  .swiper-slide {
    aspect-ratio: 1;
  }
  img {
    height: 100%;
    margin: 0 auto;
  }
  .pagination-wrapper {
    width: auto;
    height: 0;
    position: relative;
    top: 14px;
  }
  .swiper-pagination-bullet {
    background: white;
    position: relative;
    width: 12px;
    height: 12px;
    border: 2px solid black;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: black;
  }
  .swiper-pagination-bullet-active:after {
    content: " ";
    position: absolute;
    border: 1px solid white;
    width: 8px;
    height: 8px;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    z-index: 1;
  }
`;

const generateSlides = (imagesList: any) => {
  return (
    <Swiper
      className="border-b border-window"
      spaceBetween={0}
      slidesPerView={1}
      modules={[Pagination]}
      observeParents={true}
      observer={true}
      pagination={{
        el: ".pagination-wrapper",
        clickable: true,
      }}
      onSlideChange={() => null}
      onSwiper={swiper => null}
    >
      {imagesList.map((image: any) => {
        return (
          <SwiperSlide key={image.uid}>
            <img src={image.url} alt={image.uid} style={{ objectFit: "contain" }} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const verifyVariantImageIndices = (variants: any) => {
  if (!variants) return { hasIndices: false, indices: [] };
  // find a variant set to have indices
  const variantWithIndices = variants.find((variant: any) => {
    return variant.set_indices;
  });
  // if no variant set to have indices, return false
  if (!variantWithIndices)
    return { success: false, indices: [], message: "No variant has indices" };

  const uniqueIndices = [];
  for (const value of variantWithIndices.values) {
    const valueImageIndex = value.image_index;
    // make sure no indices are null
    if (valueImageIndex === null) {
      // return failure if any null index is found
      return { success: false, indices: [], message: "Some indices are null" };
    } else {
      // save unique image_indexes
      if (uniqueIndices.indexOf(valueImageIndex) === -1) {
        uniqueIndices.push(valueImageIndex);
      }
    }
  }
  // make sure number of image indices match number of variant values, otherwise return failure
  if (uniqueIndices.length !== variantWithIndices.values.length) {
    return {
      success: false,
      indices: [],
      message: "Number of indices don't match with number of variant values",
    };
  }

  // all good, save variant title
  const variantWithIndicesTitle = variantWithIndices.title;

  // return success
  return {
    success: true,
    indices: uniqueIndices,
    variantTitle: variantWithIndicesTitle,
    selectedValueIndex: variantWithIndices.preview_selectedValueIndex,
  };
};

const getImagesInRange = (array: any, startingIndex: number, length: number) => {
  const items = [];
  for (let i = startingIndex; i < startingIndex + length; i++) {
    items.push(array[i]);
  }
  return items;
};
const getFilteredImages = (allProductImages: any, indexesCheck: any) => {
  const selectedValueIndex = indexesCheck.selectedValueIndex;
  const startingIndex = indexesCheck.indices[selectedValueIndex];
  let nextIndex = indexesCheck.indices[selectedValueIndex + 1];
  if (!nextIndex) {
    nextIndex = allProductImages.length;
  }
  const length = nextIndex - startingIndex;
  const filteredImages = getImagesInRange(allProductImages, startingIndex, length);
  return filteredImages;
};

const SlideshowArea: React.FC = () => {
  // hooks
  const preview_selectedProduct = useAppSelector(
    state => state.campaignCreate.preview_selectedProduct,
  );
  const allProductImages = useAppSelector(state => state.campaignCreate.productImages)[
    preview_selectedProduct - 1
  ];
  const variants = useAppSelector(state => state.campaignCreate.variantBoxes)[
    preview_selectedProduct - 1
  ];
  const hasImages =
    preview_selectedProduct !== 0 && allProductImages && allProductImages.length > 0;

  // no images added, show empty state
  if (!hasImages) {
    return (
      <div className={classEmptyElementRoot}>
        <PSmall className="animate-pulse max-w-[80%] text-center leading-5">
          Add images to product {preview_selectedProduct} to see the slides here
        </PSmall>
      </div>
    );
  }
  // has images, verify indices
  const indexesCheck = verifyVariantImageIndices(variants);

  const imagesToDisplay = indexesCheck.success
    ? getFilteredImages(allProductImages, indexesCheck)
    : allProductImages;
  const content = generateSlides(imagesToDisplay);

  return (
    <RootDiv className={classElementRoot}>
      {content}
      {hasImages && (
        <div className="flex !justify-center w-full">
          <div className="pagination-wrapper"> </div>
        </div>
      )}
    </RootDiv>
  );
};
export default SlideshowArea;
