import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { P, PLarge, PTiny, PSmall } from "@tempoplatform/tpds/elements/typography";
import Tag from "@tempoplatform/tpds/components/tag";
import Table from "@tempoplatform/tpds/components/table";
import { Button } from "@tempoplatform/tpds/elements/buttons";
import { CampaignListItem, patchPlacementCampaignIsActive } from "../api/api";
import Modal from "@tempoplatform/tpds/components/modal";
import clsx from "clsx";

import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  data: CampaignListItem[];
  onUpdate: any;
}

let renderDate = (timestamp: number) => {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "short" });
  return <span>{`${month} ${day}, ${year}`}</span>;
};

const getColumns = (history: any, setCampaignToChange: any) => {
  const columns = [
    {
      label: "Id",
      propName: "id",
      key: "id",
    },
    {
      label: "Name",
      propName: "name",
      key: "name",
    },
    {
      label: "State",
      propName: "state",
      key: "state",
      render: (item: any) => (
        <Tag label={item.state} variant={getTagVariant(item.state)} className="h-[19px]" />
      ),
    },
    {
      label: "Start Date",
      propName: "start_date",
      key: "start_date",
      render: (item: any) => <PTiny>{renderDate(item.start_date)}</PTiny>,
    },
    {
      label: "End Date",
      propName: "end_date",
      key: "end_date",
      render: (item: any) => <PTiny>{renderDate(item.end_date)}</PTiny>,
    },
    {
      label: "Actions",
      key: "activate_deactivate",
      align: "right",
      render: (record: CampaignListItem, index: number) => (
        <div className="flex">
          <Link to={`/campaigns/placement/edit/${record.id}`} className="block">
            <Button className="mr-2 h-full" variant="secondary">
              View
            </Button>
          </Link>
          <Button variant="secondary" onClick={() => setCampaignToChange(record)}>
            {record.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
      ),
    },
  ];
  return columns;
};

function getCampaignState(campaign: any) {
  const start = moment(campaign.start_date);
  const end = moment(campaign.end_date);
  if (moment().isAfter(end)) {
    return "ENDED";
  }
  if (moment().isBetween(start, end)) {
    if (campaign.is_active) {
      return "ACTIVE";
    }
    return "INACTIVE";
  }
  if (moment().isBefore(start)) {
    return "UPCOMING";
  }
}

const getTagVariant = (campaignState: string) => {
  switch (campaignState) {
    case "ACTIVE":
      return "success";
    case "INACTIVE":
      return "warning";
    case "ENDED":
      return "default";
    case "UPCOMING":
      return "info";
    default:
      // ENDED
      return "default";
  }
};

const PlacementCampaignList: React.FC<Props> = ({ data, onUpdate }) => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [campaignToChange, setCampaignToChange] = React.useState<any>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlPageParameter = params.get("page");
  const page = urlPageParameter ? parseInt(urlPageParameter) : 1;

  const hasData = !!data && data.length > 0;
  let dataSource = hasData
    ? data.map(c => {
        return { key: c.id, state: getCampaignState(c), ...c };
      })
    : [];

  const callPatchPlacementCampaignIsActive = async () => {
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    patchPlacementCampaignIsActive(campaignToChange.id, !campaignToChange.is_active, id_token).then(
      () => {
        //todo: do a soft refresh to improve page load performance. Will need a central Campaigns redux state though.
        // window.location.reload();
        setCampaignToChange(null);
        onUpdate();
      },
    );
  };

  const confirmModal = (
    <Modal
      showOpen={true}
      title={
        campaignToChange && campaignToChange.is_active ? "Deactivate campaign" : "Activate campaign"
      }
      content={
        <P className="leading-normal">{`Are you sure you want to ${
          campaignToChange && campaignToChange.is_active ? "deactivate" : "activate"
        } this campaign?`}</P>
      }
      options={[
        {
          label: "Ok",
          variant: "info",
          callBack: () => {
            callPatchPlacementCampaignIsActive();
          },
        },
        {
          label: "Cancel",
          variant: "secondary",
          callBack: () => setCampaignToChange(null),
        },
      ]}
    />
  );

  const newCampaignButtonStyle = clsx(
    "select-none",
    "px-5 py-4 rounded-lg flex flex-col gap-y-1 cursor-pointer",
    "border-2 border-transparent",
    "bg-grey-light-scale-100 hover:bg-grey-light-scale-200",
    "hover:border-grey-light-scale-200",
    "dark:bg-grey-dark-scale-500 dark:hover:bg-grey-dark-scale-400",
    "dark:hover:border-grey-dark-scale-400",
  );

  if (!hasData) {
    return (
      <div>
        <PLarge isMedium className="">
          Placement Campaigns
        </PLarge>
        <PSmall className="text-tertiary !mt-3">No placement campaigns to display.</PSmall>
      </div>
    );
  }

  return (
    <div>
      <div>
        <PLarge>Placement Campaigns</PLarge>
        <Table
          columns={getColumns(history, setCampaignToChange)}
          data={dataSource}
          router={history}
          page={page}
        />
      </div>
      {campaignToChange && confirmModal}
    </div>
  );
};

export default PlacementCampaignList;
