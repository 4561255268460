import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Entry from "./components/entry";
import EmailVerification from "./components/onboarding/EmailVerification";
import CampaignCreate from "./components/campaignCreate/CampaignCreate";
import PlacementCampaignCreate from "./components/placementCampaignCreate/PlacementCampaignCreate";
import Placements from "./components/placements/Placements";
import CampaignView from "./components/campaign/CampaignView";
import AdvertiserReports from "./components/advertiser/Reports";
import ApiKeys from "./components/api_keys/ApiKeys";
import ProtectedRoute from "./auth/protected-route";
import { PageRoot, Container } from "@tempoplatform/tpds/elements/layout";
import AccessTokenContextWrapper from "./auth/AccessTokenContextWrapper";
import ThemeContextWrapper from "./color/ThemeContextWrapper";
import "./App.css";
// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

// EmailVerification - Entry
const App = () => {
  return (
    <ThemeContextWrapper>
      <AccessTokenContextWrapper>
        <PageRoot className="bg-grey-light-scale-100 dark:bg-black">
          <Navbar />
          <Container variant="large" className="mt-8">
            <Switch>
              <ProtectedRoute path="/" exact component={Entry} />
              <ProtectedRoute path="/campaigns/create" exact component={CampaignCreate} />
              <ProtectedRoute
                path="/campaigns/placement/create"
                exact
                component={PlacementCampaignCreate}
              />
              <ProtectedRoute
                path="/campaigns/placement/edit/:id"
                exact
                component={PlacementCampaignCreate}
              />
              <ProtectedRoute path="/campaign/view/:id" component={CampaignView} />
              <ProtectedRoute path="/campaigns" component={Entry} />
              <ProtectedRoute path="/pub" component={Entry} />
              <ProtectedRoute path="/reports" component={AdvertiserReports} />
              <ProtectedRoute path="/api-keys" component={ApiKeys} />
              {/*<SecureRoute path="/pub/applications/create" component={PublisherAppCreate}/>*/}
              {/*<SecureRoute path="/pub/applications" component={PublisherApps}/>*/}
              <Route path="/login/callback" component={Entry} />
              <Route path="/(signin|login)" component={Entry} />
              <Route path="/(signup|register)" component={Entry} />
            </Switch>
          </Container>
        </PageRoot>
      </AccessTokenContextWrapper>
    </ThemeContextWrapper>
  );
};

export default App;
