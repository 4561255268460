import React from "react";
import InitialScreenContent from "./InitialScreenContent";
import InitialScreenRedirectToWebpageContent from "./InitialScreenRedirectToWebpageContent";
import InitialScreenMainMedia from "./InitialScreenMainMedia";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

const InitialScreen: React.FC = () => {
  const [soundOn, setSoundOn] = React.useState(false);
  const adType = useAppSelector(state => state.campaignCreate.ad_type);
  return (
    <div className="w-full h-full absolute top-0 left-0 translate-x-0 transition-transform duration-300 ease-in-out">
      {adType !== "Redirect to Webpage" && (
        <InitialScreenContent soundOn={soundOn} setSoundOn={setSoundOn} />
      )}
      {adType === "Redirect to Webpage" && (
        <InitialScreenRedirectToWebpageContent soundOn={soundOn} setSoundOn={setSoundOn} />
      )}
      <InitialScreenMainMedia soundOn={soundOn} />
    </div>
  );
};
export default InitialScreen;
