import React from "react";
import { PSmall } from "@tempoplatform/tpds/elements/typography";
import NumberInput from "@tempoplatform/tpds/components/input/number";
import { Placement } from "../types/types";
import { useAppDispatch } from "../../../app/hooks";
import { updatePlacement } from "../reducer";
import { normaliseWH } from "../../../shared/CalculateImageAssetSize";

interface Props {
  placement: Placement;
}

const coordinatePrefixStyle =
  "!text-secondary border-2 border-[#ededed] !border-r-0 dark:border-[#384147] h-full flex items-center px-2.5 rounded-tl rounded-bl";

const PlacementWidthHeight = (props: Props) => {
  const { placement } = props;
  const dispatch = useAppDispatch();
  const [orientationIndex, setOrientationIndex] = React.useState(null);

  const updatePlacementWidth = (value: string) => {
    dispatch(updatePlacement({ ...placement, width: parseInt(value) }));
  };

  const updatePlacementHeight = (value: string) => {
    dispatch(updatePlacement({ ...placement, height: parseInt(value) }));
  };

  const size: any = normaliseWH(placement.width, placement.height);

  return (
    <div className="flex flex-col gap-3 h-full shadow bg-transparent p-6 rounded-lg relative border border-transparent dark:border-[rgba(255,255,255,0.1)]">
      <div className="grid grid-cols-2 gap-4 items-start w-full h-full">
        <div className="flex flex-col gap-4 w-full items-start dark:bg-grey-dark-scale-700">
          <div className="flex gap-x-1 space-between items-end">
            <div className="w-full">
              <PSmall isMedium className="text-secondary mb-2">
                Image Asset Size
              </PSmall>
              <div className="flex gap-x-1">
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    Width
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={placement.width.toString() || "0"}
                    allowFloats={false}
                    onChangeCallback={(value: string) => updatePlacementWidth(value)}
                  />
                </div>
                <div className="flex">
                  <PSmall isMedium className={coordinatePrefixStyle}>
                    Height
                  </PSmall>
                  <NumberInput
                    className="!rounded-tl-[0] !rounded-bl-[0]"
                    initialValue={placement.height.toString() || "0"}
                    allowFloats={false}
                    onChangeCallback={(value: string) => updatePlacementHeight(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full gap-x-1 grid content-center ">
        <PSmall isMedium className="text-secondary mb-2" style={{ textAlign: "center" }}>
          Image Asset Size - Width: {size.width}, Height: {size.height}
        </PSmall>
      </div>
    </div>
  );
};
export default PlacementWidthHeight;
