import { DatePicker } from "antd";
import React from "react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  updateDailyBudget,
  updateCpmBudget,
  updateStartEndDateValues,
} from "../campaignCreateSlice";
import { PLarge, FormLabelSmall, Red } from "@tempoplatform/tpds/elements/typography";
import { Separator } from "@tempoplatform/tpds/elements/layout";
import PriceInput from "@tempoplatform/tpds/components/input/price";

const { RangePicker } = DatePicker;

// @ts-ignore
function disabledDate(current) {
  return current && current < moment().endOf("day");
}

const getDateRangeValue = (startEndDates: any) => {
  if (!startEndDates) return null;
  if (!startEndDates[0]) return null;
  if (!startEndDates[1]) return null;
  const dateStart = moment(startEndDates[0], "YYYY-MM-DD");
  if (!dateStart.isValid()) return null;
  const dateEnd = moment(startEndDates[1], "YYYY-MM-DD");
  if (!dateEnd.isValid()) return null;
  return [dateStart, dateEnd];
};

const BudgetAndDuration = () => {
  const targetingBudgetDaily = useAppSelector(state => state.campaignCreate.budget_daily);
  const targetingBudgetCpm = useAppSelector(state => state.campaignCreate.budget_cpm);
  const startEndDates = useAppSelector(state => state.campaignCreate.start_end_dates);
  const dispatch = useAppDispatch();
  const dateRangeValue = getDateRangeValue(startEndDates);

  return (
    <>
      <PLarge isBold className="mt-4 mb-3">
        Budget & Duration
      </PLarge>
      <div>
        <FormLabelSmall className="mb-2">
          <Red>*</Red> Start & End
        </FormLabelSmall>
        <RangePicker
          allowEmpty={[false, false]}
          disabledDate={disabledDate}
          // @ts-ignore
          defaultValue={dateRangeValue}
          onChange={(dates: any, dateStrings: any) => {
            dispatch(updateStartEndDateValues(dateStrings));
          }}
        />
      </div>
      <div>
        <FormLabelSmall className="p-0 mt-4 mb-2">
          <Red>*</Red> Daily Budget
        </FormLabelSmall>
        <PriceInput
          initialValue={targetingBudgetDaily?.toString() || undefined}
          currencySymbol="$"
          onChangeCallback={(value: string) => dispatch(updateDailyBudget(value))}
        />
      </div>
      <div>
        <FormLabelSmall className="p-0 mt-4 mb-2">
          <Red>*</Red> CPM
        </FormLabelSmall>
        <PriceInput
          initialValue={targetingBudgetCpm?.toString() || undefined}
          currencySymbol="$"
          onChangeCallback={(value: string) => dispatch(updateCpmBudget(value))}
        />
      </div>
      <br />
    </>
  );
};
export default BudgetAndDuration;
