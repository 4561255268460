const validate = (state: any) => {
  const errors = [];
  // console.log(state)
  if (!state.targeting_gender) {
    errors.push("Target gender");
  }
  if (!parseInt(state.targeting_age.min)) {
    errors.push("Minimum age");
  }
  if (!parseInt(state.targeting_age.max)) {
    errors.push("Maximum age");
  }
  if (parseInt(state.targeting_age.min) < 13) {
    errors.push("Minimum age too low");
  }
  if (parseInt(state.targeting_age.max) > 99) {
    errors.push("Maximum age too high");
  }
  if (state.targeting_age.min > state.targeting_age.max) {
    errors.push("Maximum age is below minimum age");
  }
  if (!state.targeting_languages || state.targeting_languages.length === 0) {
    errors.push("Target languages");
  }
  if (!state.targeting_locations || state.targeting_locations.length === 0) {
    errors.push("Target locations");
  }
  if (!state.targeting_interests || state.targeting_interests.length === 0) {
    errors.push("Target interests");
  }
  // if (!state.targeting_goal) {
  //   errors.push("Optimization goal");
  // }
  // if (!state.targeting_bid_type) { // redundant
  //   errors.push("Optimization bid type");
  // }
  if (!state.start_end_dates[0]) {
    errors.push("Duration: start date");
  }
  if (!state.start_end_dates[1]) {
    errors.push("Duration: end date");
  }
  if (!state.budget_daily) {
    errors.push("Daily budget");
  }
  if (!state.budget_cpm) {
    errors.push("CPM budget");
  }
  return {
    validated: errors.length === 0,
    errors,
  };
};

export default validate;
